import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import postService from '../services/postService';
import ReactPlayer from 'react-player';
import { getRole, getUser } from '../auth/auth';



function PostContentLeader(props) {
    const params = useParams();
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState(null);
    const [imageOrVideoOfAPostDelete ,setImageOrVideoOfAPostDelete] = useState(null);
    const closeModalToDeleteAnImage = useRef(null)
    const closeModalToDeleteAnvideo = useRef(null)
    
    
    const listPostInCommunities = async() => {
        setLoading(true);
        try {
            const responseOfAPostOfKing = await postService.showPost(params.id)
            console.log('see a king ===>>> ',responseOfAPostOfKing)
            if (responseOfAPostOfKing.errorResponse) {
                toast.error(responseOfAPostOfKing.message)
            } else {
                setPost(responseOfAPostOfKing);
                //setTotalNumberOfPage(responseOfAPostOfKing.totalResult)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listPostInCommunities()
    }, []);

    const handleDeleteImage = async (communityUpdateOrDelete) => {
        try {
            setLoading(true)
            const responseDeleteImage = await postService.deleteAnImageOfAPost(communityUpdateOrDelete.id)
            if (responseDeleteImage?.errorResponse) {
                toast.error(responseDeleteImage.message)
            } else {
                console.log("Voir s'il clique sur un bouton ===>>", closeModalToDeleteAnImage.current);
                closeModalToDeleteAnImage.current.click()
                toast.success("L'image a été Supprimé avec succès")
                listPostInCommunities();
            } 
        } catch (error) {
            console.error("Erreur lors de la suppression de la chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la communauté.")
        } finally {
            setLoading(false)
        }
    };

    const handleDeleteVideo = async (communityUpdateOrDelete) => {
        try {
            setLoading(true)
            const responseDeleteVideo = await postService.deleteAnVideoOfAPost(communityUpdateOrDelete.id)
            if (responseDeleteVideo?.errorResponse) {
                toast.error(responseDeleteVideo.message)
            } else {
                closeModalToDeleteAnvideo.current.click()
                toast.success("La vidéo a été Supprimé avec succès")
                listPostInCommunities();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression de la chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la communauté.")
        } finally {
            setLoading(false)
        }
    };
    
    return (
        <>
            <Header />
            <div className="accordion-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="accordion-wn-wp mg-t-30">
                                <div className="accordion-hd">
                                    <h2>Filtre par la recherche et par le nombre d'élément</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                        <div className="accordion-stn" >
                                            <div className="col-6">
                                                <div class="dataTables_length" id="data-table-basic_length">
                                                    <label>
                                                        Présente 
                                                        <select name="data-table-basic_length" aria-controls="data-table-basic" class="">
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select> 
                                                        entrées
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div id="data-table-basic_filter" class="dataTables_filter">
                                                    <label>Search:
                                                        <input 
                                                            type="search" 
                                                            class="" 
                                                            placeholder="" 
                                                            aria-controls="data-table-basic"
                                                            // onChange={handleSearchAnObject}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Affichage des images */}
            {post?.post?.photos?.length == 0 
                ? (
                    <div class="form-element-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-element-list">
                                        <div class="basic-tb-hd">
                                            <h2 style={{color: "red"}}>Il n'ya pas d'image</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                )
                : (
                    <div className="container">
                        <h2>Images</h2>
                        <div className="row"  
                            style={{
                            overflowY: "auto", // Ajout du overflowY
                            height: "450px",   // Limite la hauteur pour que le overflowY soit visible
                        }}>
                            {/* <!-- Première carte --> */}
                            {post?.post?.photos?.map((photo, index) => (
                                <div key={photo.id || index} className="col-md-4" style={{ marginTop: "7px" }}>
                                    <div 
                                        className="thumbnail" 
                                    >
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "300px",
                                                objectFit: "cover",
                                            }}
                                            src={`${process.env.REACT_APP_SERVER}${photo?.path}`}
                                            alt={`Photo ${photo.id || index}`}
                                        />
                                        {
                                            (getUser().community_id === post.community_id || getRole() === "ROLE_ADMIN") && (
                                                <div className="caption text-center">
                                                    <button 
                                                        className="btn btn-danger btn-block"
                                                        data-toggle="modal"
                                                        data-target="#myModalToDeleteAnImage"
                                                        onClick={() => setImageOrVideoOfAPostDelete(photo)}
                                                    >
                                                        Supprimer
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                )
            }
            
            {/* Affichage des Vidéo */}
            {post?.post?.videos?.length == 0 
                ? (
                    <div class="form-element-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-element-list">
                                        <div class="basic-tb-hd">
                                            <h2 style={{color: "red"}}>Il n'ya pas de video</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                )
                : (
                    <div className="container">
                        <h2>Vidéos</h2>
                        <div className="row">
                            {/* Parcours des vidéos dans les photos */}
                            {post?.post?.videos?.map((video, index) => (
                                <div key={video.id || index} className="col-md-6" style={{ marginTop: "7px" }}>
                                    <div
                                        className="thumbnail"
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_SERVER}${video?.miniature})`, // Image de fond
                                            backgroundSize: 'cover', // S'assure que l'image couvre toute la div
                                            backgroundPosition: 'center', // Centrer l'image
                                            height: '400px', // Fixe la hauteur du conteneur
                                            position: 'relative', // Pour que la vidéo s'affiche au-dessus de l'image
                                        }}
                                    >
                                        <ReactPlayer
                                            url={`${process.env.REACT_APP_SERVER}${video?.path}`}
                                            width="100%" // Remplir la largeur du conteneur
                                            height="100%" // Faire en sorte que la vidéo occupe toute la hauteur du conteneur
                                            playing={false} // Ne pas démarrer la lecture automatiquement
                                            controls={true} // Afficher les contrôles de lecture
                                            style={{
                                                position: 'absolute', // Positionner la vidéo au-dessus de l'image
                                                top: 0,
                                                left: 0,
                                            }}
                                            light={process.env.REACT_APP_SERVER+video.miniature}
                                        />
                                        <div className="caption text-center" style={{zIndex: "100000000000", position: "absolute", bottom: "0%" }}>
                                            <button 
                                                className="btn btn-danger btn-sm"
                                                data-toggle="modal"
                                                data-target="#myModalToDeleteAnVideo"
                                                onClick={() => setImageOrVideoOfAPostDelete(video)}
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
            {/* Modal to delete an image */}
            <div id="myModalToDeleteAnImage" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    {/* Contenu du modal */}
                    <div className="modal-content">
                        {/* En-tête du modal */}
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title text-danger">Confirmer la suppression</h4>
                        </div>
                        {/* Corps du modal */}
                        <div className="modal-body">
                            <p>Êtes-vous sûr de vouloir supprimer cette image ? Cette action est irréversible.</p>
                        </div>
                        {/* Pied du modal */}
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => 
                                handleDeleteImage(imageOrVideoOfAPostDelete)}
                            >
                                Supprimer
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDeleteAnImage}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to delete an video */}
            <div id="myModalToDeleteAnVideo" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    {/* Contenu du modal */}
                    <div className="modal-content">
                        {/* En-tête du modal */}
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title text-danger">Confirmer la suppression de {imageOrVideoOfAPostDelete?.title}</h4>
                        </div>
                        {/* Corps du modal */}
                        <div className="modal-body">
                            <p>Êtes-vous sûr de vouloir supprimer cette Cette video ? Cette action est irréversible.</p>
                        </div>
                        {/* Pied du modal */}
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => 
                                handleDeleteVideo(imageOrVideoOfAPostDelete)}
                            >
                                Supprimer
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDeleteAnvideo}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PostContentLeader;