import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import suggestionService from '../services/suggestionsServices';


const Suggestions = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [totalNumberOfPage, setTotalNumberOfPage]= useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(20);
  const closeModalToDelete = useRef(null); 
  const [suggestionUpadateOrDelete, setSuggestionUpdateOrDelete]= useState(null) 

  const listSuggestions = async(search, currentPage, numberPerPage) => {
    const data = {};
    data.search = search;
    data.currentPage = currentPage+1;
    data.numberPerPage = numberPerPage;
    setLoading(true);
    try {
        const responseToListAllSuggestions = await suggestionService.getSuggestions(data)
        if (responseToListAllSuggestions.errorResponse) {
            toast.error(responseToListAllSuggestions.message)
        } else {
          setSuggestions(responseToListAllSuggestions.results);
            setTotalNumberOfPage(responseToListAllSuggestions.numberOfPages)
        }
    } catch (error) {
        toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    listSuggestions(search, currentPage, numberPerPage)
  }, [search, currentPage, numberPerPage])

  const deleteSuggestion = async (id) => {
    try {
        setLoading(true)
        const response = await suggestionService.deleteSuggestion(id)
        if (response?.errorResponse) {
            toast.error(response.message)
        } else {
            toast.success("La suggestion a été Supprimé avec succès")
            listSuggestions(search, currentPage, numberPerPage);
            closeModalToDelete.current.click();
        }
        
    } catch (error) {
        console.error("Erreur lors de la suppression du post chambre:", error)
        toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
    } finally {
        setLoading(false)
    }
}
    return (
        <>
          <Header />
          <div class="container">
            <h2 class="text-center">Suggestions des utilisateurs</h2>
            <div class="suggestions-container">
              {
                suggestions.map((suggestion, index) => (
                  <div key={index} class="suggestion-item">
                    <div class="user-info">
                      <div class="user-name">Alice</div>
                      <div class="user-message">{suggestion.message}</div>
                    </div>
                    <button 
                      class="btn btn-danger btn-delete" 
                      data-toggle="modal" 
                      data-target="#myModalToDeleteASuggestion"
                      onClick={() => setSuggestionUpdateOrDelete(suggestion)}
                    >
                      Delete
                    </button>
                  </div>
                ))
              }
              
            </div>  
          </div>
          {/* Modal to delete */}
          <div class="modal fade" id="myModalToDeleteASuggestion" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      {/* <!-- Modal Header --> */}
                      <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                          <h4 class="modal-title" id="deleteModalLabel">Confirmation de Suppression</h4>
                      </div>
                      {/* <!-- Modal Body --> */}
                      <div class="modal-body">
                          <p>Êtes-vous sûr de vouloir supprimer cette suggestion ? Cette action est irréversible.</p>
                      </div>
                      {/* <!-- Modal Footer --> */}
                      <div class="modal-footer">
                          <button 
                              type="button" 
                              class="btn btn-default" 
                              data-dismiss="modal"
                              ref={closeModalToDelete}
                          >
                              Annuler
                          </button>
                          <button 
                              type="button" 
                              class="btn btn-danger" 
                              onClick={() => deleteSuggestion(suggestionUpadateOrDelete.id)}
                          >
                              Confirmer la Suppression
                          </button>
                      </div>
                  </div>
              </div>
          </div>

          <Footer />
        </>
    );
};

export default Suggestions;