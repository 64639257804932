import { sPOST, sDELETE, sPOST_FILE, sGET } from "../http/http";
import backendUrls from '../urls/url';

const royalPaceService = {
    listPostInRoyalService: async function(data) {
        const url = backendUrls.RoyalPalace.listPostsInRoyalPalace;
        const result = await sPOST(url, data).then((r) => r.json())
        return result
    }, 
}

export default royalPaceService;