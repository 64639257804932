import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function HandingPostInACommunity(props) {
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };
    return (
        <>
           <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un Post</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <div className="accordion-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="accordion-wn-wp mg-t-30">
                                    <div className="accordion-hd">
                                        <h2>Création d'un Post</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                            <div className="accordion-stn">
                                                <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                                    <div className="panel panel-collapse notika-accrodion-cus">
                                                        <div className="panel-heading" role="tab">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                                    Cliquez ici pour voir le formulaire
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                                            <div className="panel-body">
                                                                <div className="form-example-wrap">
                                                                    <div className="form-example-int">
                                                                        <div className="form-group">
                                                                            <label>Nom</label>
                                                                            <div className="nk-int-st">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="form-control input-sm" 
                                                                                    placeholder="Enter Email" 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-example-int">
                                                                        <div className="form-group">
                                                                            <label>Description</label>
                                                                            <div className="nk-int-st">
                                                                                <textarea 
                                                                                    type="text" 
                                                                                    className="form-control input-sm" 
                                                                                    placeholder="Veuillez saisir la description" 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-example-int">
                                                                        <div className="form-group">
                                                                            <label>Sélectionner une image</label>
                                                                            <div className="nk-int-st">
                                                                                <input 
                                                                                    type="file" 
                                                                                    className="form-control input-sm" 
                                                                                    placeholder="Enter Email" 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="form-example-int mg-t-15">
                                                                        <button className="btn btn-success notika-btn-success">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="accordion-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="accordion-wn-wp mg-t-30">
                                        <div className="accordion-hd">
                                            <h2>Filtre par la recherche et par le nombre d'élément</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                                <div className="accordion-stn" >
                                                    <div className="col-6">
                                                        <div class="dataTables_length" id="data-table-basic_length">
                                                            <label>
                                                                Show 
                                                                <select name="data-table-basic_length" aria-controls="data-table-basic" class="">
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select> 
                                                                entries
                                                            </label>
                                                        </div>
                                                    </div>
                                                       

                                
                                                    <div className='col-6'>
                                                        <div id="data-table-basic_filter" class="dataTables_filter">
                                                            <label>Search:
                                                                <input type="search" class="" placeholder="" aria-controls="data-table-basic"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="inbox-area" style={{marginTop: "15px"}}>        */}
                        <div class="container">    
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">  
                                    {/* <div className="dropdown-list mg-t-30"> */}
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image4.jpg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image5.jpg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image4.jpg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image4.jpg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image4.jpg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4" style={{height: "430.90px"}}>
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image13.jpeg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image14.jpeg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image14.jpeg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <div class="thumbnail">
                                            <div style={{width: "350px"}}>
                                                <div style={{width: "100%"}}>
                                                    <img width="100%" src="/images/images/image14.jpeg" alt="Image 1" />
                                                </div>
                                            </div>
                                            <div class="caption">
                                                <h3>Titre de la Carte 1</h3>
                                                <p>Ceci est une description de la carte 1.</p>
                                                <p>
                                                    <button 
                                                        class="btn notika-btn-success" 
                                                        role="button" data-toggle="modal" 
                                                        data-target="#myModalthree"
                                                    >
                                                        Description
                                                    </button>
                                                    <button 
                                                        class="btn notika-btn-amber" 
                                                        style={{marginLeft: "5px"}}
                                                        data-toggle="modal" 
                                                        data-target="#myModaltwo"
                                                    >Modifier</button>
                                                    <button class="btn btn-danger pull-right" role="button">Supprimer</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    {/* </div> */}
                    </div>
                </>
            )}           
            <div class="modal fade" id="myModaltwo" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="myModalthree" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer /> 
        </>
    );
}

export default HandingPostInACommunity;