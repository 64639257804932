import { sPOST, sGET, sDELETE, formDataPOST_FETCH } from "../http/http";
import backendUrls from '../urls/url';

const dynastyService = {
  createAMemberOfADynasty: async function (data) {
    const url = backendUrls.Dynasty.CreateAMemberOfTheDynasty;
    const result = await formDataPOST_FETCH(url, data).then((r) =>
    r.json())
    return result
  },
  listAllMembersOfTheDynasty: async function (data) {
    const url = backendUrls.Dynasty.listAllMembersOfTheDynasty;
    const result = await sPOST(url, data)
    .then((r) => r.json())
    return result
  },
  updateAMemberOfDynasty: async function (id, data) {
    const url = backendUrls.Dynasty.updateAMemberOfDynasty+id;
    const result = await formDataPOST_FETCH(url, data).then((r) => r.json())
    return result
  },
  deleteAMemberOfDynasty: async function(id) {
    const url = backendUrls.Dynasty.deleteAMemberOfDynasty+id;
    const result = await sDELETE(url)
    return result
  }
}

export default dynastyService;