import { sPOST, sDELETE, sPOST_FILE , formDataPOST_FETCH} from "../http/http";
import backendUrls from '../urls/url';

const clanService = {
    createClan: async function (data) {
      const url = backendUrls.Clan.createClan
      console.log('url ===>> ', url)
      const result = await formDataPOST_FETCH(url, data).then((r) =>
      r.json())
      return result
      //return { status: success, message: message }
    },
    listClans: async function (data) {
      const url = backendUrls.Clan.listClans;
      console.log('url qui veut bloquer ===>> ', url)
      const result = await sPOST(url, data)
      .then((r) => r.json())
      return result
    },
    deleteAClan: async function(id) {
      const url = backendUrls.Clan.deleteClan+id;
      const result = await sDELETE(url)
      return result
    },
    updateClan: async function (id, data) {
      const url = backendUrls.Clan.updateClan+id;
      const result = await sPOST(url, data).then((r) => r.json())
      return result
    }
}

export default clanService;