import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import communityService from '../services/communityService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getIdAndtypeCommunity } from '../features/postInCommunity/postInCommunitySlice';
import ReactPaginate from 'react-paginate';


function DiasporaToPostsNotNews(props) {
    const dispatch = useDispatch()
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [search, setSearch] = useState("");
    const [numberPerPage, setNumberPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [diasporasCommunity, setDiaporasCommunity] = useState([]);
    const [totalNumberOfPage , setTotalNumberOfPage] = useState(null);
    const [diasporaCommunity ,setDiasporaCommunity] = useState(null);
    const closeModalToUpdate = useRef(null);
    const navigate = useNavigate();

    const handleClick = (nameCommunity) => {
        dispatch(getIdAndtypeCommunity({ communityId: nameCommunity.id, type: nameCommunity.type }));
        // Naviguer vers /posts/:nameCommunity1
        navigate(`/posts-in-diaspora-not-only-news/${nameCommunity.name}/${nameCommunity.id}`);
    };


    // Validation schema with Yup
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required('Le nom est requis'),
    description: Yup.string().required('La description est requise'),
    type: Yup.string().required('Le type est requis').oneOf(['local', 'diaspora', 'royaume']),
  });

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    // Initial values
    const initialValues = {
        nom: '',
        description: '',
        type: '',
    };

    const listAllDiaporaCommunities = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page; 
        data.numberPerPage = numberPerPage;
        data.type = "diaspora"
        setLoading(true);
        try {
            const responseDiaporaCommunity = await communityService.listAllCommunity(data)
            if (responseDiaporaCommunity.errorResponse) {
                toast.error(responseDiaporaCommunity.message)
            } else {
                setDiaporasCommunity(responseDiaporaCommunity.results);
                setTotalNumberOfPage(responseDiaporaCommunity.numberOfPages)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listAllDiaporaCommunities(search, currentPage, numberPerPage)
    }, [search, currentPage, numberPerPage])

    // Handle form submission
    const handleSubmit = async (values, { resetForm }) => {
        // alert(JSON.stringify(values, null, 4))
        //console.log('Form values:', values);
        setLoading(true);
        const communityData = {
            name: values.nom,
            description: values.description,
            type: values.type,
        }

        //alert(values, null, 4)
        try {
            const responseCommunity = await communityService.createCommunity(communityData)
            if (responseCommunity.errorResponse) {
                toast.error("Erreur lors de la création d'une communauté")
            } else {
                toast("Cette diapora a été crée avec succès")
                resetForm()
            }
            
        } catch (error) {
            
        }
    };

    const handleSearchDiaspora = (event) => {
        const keywords = event.target.value;
        setSearch(keywords);
        setCurrentPage(0);
    }

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        setCurrentPage(currentPageu)
        return ;
    
    };

    const handleUpdateDiasporaSubmit = async(values) => {
        const communityData = {
            name: values.name,
            description: values.description,
            type: values.type,
        };

        const responseUpdateCommunity = await communityService.updateDetailOfACommunity(diasporaCommunity.id, communityData)
        try {
            if(responseUpdateCommunity.errorResponse) {
                toast.error(responseUpdateCommunity.message)
            } else {
                listAllDiaporaCommunities(search, currentPage, numberPerPage)
                toast("La création de type danse traditionnelle a été éffectué avec succès")
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du post")
            //console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    const deleteADiaspora = async (communityUpdateOrDelete) => {
        // console.log('Voir id de la communauté ==> ', communityUpdateOrDelete.id)
        try {
            setLoading(true)
            const responseDeleteCommunity = await communityService.deleteACommunity(communityUpdateOrDelete.id)
            if (responseDeleteCommunity?.errorResponse) {
                toast.error(responseDeleteCommunity.message)
            } else {
                closeModalToUpdate.current.click()
                toast.success("La communauté a été Supprimé avec succès")
                listAllDiaporaCommunities(search, currentPage, numberPerPage);
            }
            
        } catch (error) {
            //console.error("Erreur lors de la suppression de la chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la communauté.")
        } finally {
            setLoading(false)
        }
    };
    return (
        <>
            <Header />  
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'une diapora</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            {isChecked ? 
                (
                    <div class="accordion-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="accordion-wn-wp">
                                        <div class="accordion-hd">
                                            <h2>Formulaire de création d'une diapora</h2>
                                            {/* <p>Ici vous aurez la possibilité de créer une diapora en remplissant un formulaire. <code>Cliquer ici pour créer une diapora</code></p> */}
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12">
                                                <div class="accordion-stn">
                                                    <div class="panel-group" data-collapse-color="nk-green" id="accordionGreen" role="tablist" aria-multiselectable="true">
                                                        <div class="panel panel-collapse notika-accrodion-cus">
                                                            <div class="panel-heading" role="tab">
                                                                <h4 class="panel-title">
                                                                    <a data-toggle="collapse" data-parent="#accordionGreen" href="#accordionGreen-one" aria-expanded="false">
                                                                            Cliquez ici pour créer une diapora
                                                                        </a>
                                                                </h4>
                                                            </div>
                                                            <div id="accordionGreen-one" class="collapse in" role="tabpanel">
                                                                <div class="panel-body">
                                                                    {/* <!-- Form Examples area start--> */}
                                                                        {/* <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
                                                                        <Formik
                                                                            initialValues={initialValues}
                                                                            validationSchema={validationSchema}
                                                                            onSubmit={handleSubmit}
                                                                        >
                                                                            {() => (
                                                                                <Form>
                                                                                {/* Champ Nom */}
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                    <label htmlFor="nom">Nom</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="nom"
                                                                                            id="nom"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez le nom"
                                                                                        />
                                                                                        <ErrorMessage name="nom" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                    </div>
                                                                                </div>

                                                                                {/* Champ Description */}
                                                                                <div className="form-example-int mg-t-15">
                                                                                    <div className="form-group">
                                                                                    <label htmlFor="description">Description</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Veuillez saisir la description du concernant cette diapora"
                                                                                        />
                                                                                        <ErrorMessage name="description" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                    </div>
                                                                                </div>

                                                                                {/* Champ Type */}
                                                                                <div className="form-example-int mg-t-15">
                                                                                    <div className="form-group">
                                                                                    <label>Type</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field as="select" name="type" className="form-control input-sm">
                                                                                            <option value="">Veuillez sélectionner le type</option>
                                                                                            <option value="local">Local</option>
                                                                                            <option value="diaspora">Diaspora</option>
                                                                                            <option value="royaume">Royaume</option>
                                                                                        </Field>
                                                                                        <ErrorMessage name="type" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                    </div>
                                                                                </div>

                                                                                {/* Bouton de soumission */}
                                                                                <div className="form-example-int mg-t-15">
                                                                                    <button type="submit" className="btn" style={{ background: "rgb(34, 58, 102)", color: "white" }}>
                                                                                    Créer un post
                                                                                    </button>
                                                                                </div>
                                                                                </Form>
                                                                            )}
                                                                        </Formik>
                                                                    </div>             
                                                                {/* <!-- Form Examples area End--> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <div className="inbox-area" style={{marginTop: "15px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="inbox-text-list sm-res-mg-t-30">
                                        <div className="form-group">
                                            <div className="nk-int-st search-input search-overt">
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Entrer le nom d'une communauté..."
                                                    onChange={handleSearchDiaspora} 
                                                />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-inbox">
                                                <tbody>
                                                    {diasporasCommunity.map((diasporaCommunity, index) => (
                                                        <tr key={index} className="active">
                                                            <td>
                                                                <a role='button' onClick={() => handleClick(diasporaCommunity)}>
                                                                    {diasporaCommunity.id}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a role='button' onClick={() => handleClick(diasporaCommunity)}>
                                                                    {diasporaCommunity.name}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a role='button' onClick={() => handleClick(diasporaCommunity)}>
                                                                    {diasporaCommunity.type}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <button 
                                                                    type="button" 
                                                                    className="btn"
                                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }} 
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalProfil"
                                                                    onClick={() => setDiasporaCommunity(diasporaCommunity)}
                                                                >
                                                                    Profil
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <a 
                                                                    className="btn"
                                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                                    role="button"
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalUpdate"
                                                                    onClick={() => setDiasporaCommunity(diasporaCommunity)}
                                                                >
                                                                    Modifier
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a 
                                                                    className="btn btn-danger"
                                                                    role="button"
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalToDeleteDiaspora"
                                                                    onClick={() => setDiasporaCommunity(diasporaCommunity)}
                                                                >
                                                                    Supprimer
                                                                </a>
                                                            </td>
                                                            
                                                        </tr>
                                                    ))} 
                                                </tbody>
                                            </table>
                                            <ReactPaginate
                                                key={currentPage}
                                                initialPage={currentPage}
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                pageCount={totalNumberOfPage}
                                                // marginPagesDisplayed={2}
                                                // pageRangeDisplayed={3}
                                                onPageChange={handleChangeCurrentPage}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeLinkClassName={"active"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* Modal to see profil diaspora */}
            <div class="modal fade" id="myModalProfil" tabindex="-1" role="dialog" aria-labelledby="myModalProfilLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title" id="myModalProfilLabel">Informations</h4>
                        </div>
                        <div class="modal-body">
                            <p><strong>Nom :</strong> <span id="modal-name">{diasporaCommunity?.name}</span></p>
                            <p><strong>Type :</strong> <span id="modal-type">{diasporaCommunity?.type}</span></p>
                            <p><strong>Description :</strong> <span id="modal-description">{diasporaCommunity?.description}</span></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to update a diaspora */}
            <div className="modal fade" id="myModalUpdate" role="dialog">
                <div className="modal-dialog modal-large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                            &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2>Modifier une communauté</h2>
                            <Formik
                            enableReinitialize={true}
                            initialValues={{
                                name: diasporaCommunity?.name || '',
                                description: diasporaCommunity?.description || '',
                                type: diasporaCommunity?.type || 'local',
                            }}
                            validationSchema={Yup.object({
                                name: Yup.string().required('Le nom est requis'),
                                type: Yup.string().required('Le type est requis'),
                                description: Yup.string().required('La description est requise'),
                            })}
                            onSubmit={handleUpdateDiasporaSubmit}
                            >
                            {() => (
                                <Form className="form-example-wrap">
                                <div className="form-example-int">
                                    <div className="form-group">
                                    <label>Nom</label>
                                    <div className="nk-int-st">
                                        <Field
                                        type="text"
                                        name="name"
                                        className="form-control input-sm"
                                        placeholder="Entrez le nom"
                                        />
                                        <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="text-danger"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="form-example-int">
                                    <div className="form-group">
                                    <label>Type</label>
                                    <div className="nk-int-st">
                                        <Field
                                        as="select"
                                        name="type"
                                        className="form-control input-sm"
                                        >
                                        <option value="local">Local</option>
                                        <option value="diaspora">Diaspora</option>
                                        <option value="royaume">Royaume</option>
                                        </Field>
                                        <ErrorMessage
                                        name="type"
                                        component="div"
                                        className="text-danger"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="form-example-int">
                                    <div className="form-group">
                                    <label>Description</label>
                                    <div className="nk-int-st">
                                        <Field
                                        as="textarea"
                                        name="description"
                                        className="form-control input-sm"
                                        placeholder="Veuillez saisir la description"
                                        />
                                        <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="text-danger"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="form-example-int mg-t-15">
                                    <button
                                    type="submit"
                                    className="btn btn-success notika-btn-success"
                                    >
                                    Enregistrer
                                    </button>
                                </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button
                            ref={closeModalToUpdate}
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                            >
                            Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to delete */}
            <div class="modal animated rubberBand" id="myModalToDeleteDiaspora" role="dialog">
                <div class="modal-dialog modals-default">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button"  class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Suppression de la communauté {diasporaCommunity?.name}</h2>
                            <p>Voulez-vous vraiment supprimer cette communauté ?</p>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn brn-danger notika-btn-danger" 
                                data-dismiss="modal" 
                                onClick={() => deleteADiaspora(diasporaCommunity)}
                            >
                                Supprimer
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToUpdate}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default DiasporaToPostsNotNews;