import { sPOST, sDELETE, sPOST_FILE , formDataPOST_FETCH} from "../http/http";
import backendUrls from '../urls/url';

const photoService = {
    
    deleteAPhoto: async function(id) {
      const url = backendUrls.Photo.deleteKingPhoto+id;
      const result = await sDELETE(url)
      return result
    },
}

export default photoService;