import { sPOST, sDELETE, sPOST_FILE , formDataPOST_FETCH} from "../http/http";
import backendUrls from '../urls/url';

const videoService = {  
    deleteAMovie: async function(id) {
      const url = backendUrls.Video.deleteVideo+id;
      const result = await sDELETE(url)
      return result
    },
}

export default videoService;