import { sPOST, sDELETE, sPOST_FILE , formDataPOST_FETCH} from "../http/http";
import backendUrls from '../urls/url';

const podcastService = {
    createAPodcat: async function (data) {
        const url = backendUrls.Podcast.createPodcast;
        const result = await sPOST(url, data).then((r) =>
        r.json())
        return result
    },
    listPodcast: async function (data) {
        const url = backendUrls.Podcast.listPodcasts;
        const result = await sPOST(url, data)
        .then((r) => r.json())
       return result
    },
    deletePodcast: async function(id) {
        const url = backendUrls.Podcast.deletePodcast+id
        const result = await sDELETE(url)
        return result
    }, 
    updatePodcast: async function (id, data) {
        const url = backendUrls.Podcast.updatePodcast+id;
        const result = await sPOST(url, data).then((r) => r.json())
        return result
    }
}

export default podcastService;
