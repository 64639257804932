import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import programBroadcastService from '../services/programBroadcastService';
import { toast } from 'react-toastify';
import podcastService from '../services/podcastService';
import teamRadioService from '../services/teamRadioService';


function Teams(props) {
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [TeamsRadio, setTeamsRadio] = useState([])
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const [teams, setTeams] = useState([]);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null);
    const [teamUpdateOrDelete, setTeamUpdateOrDelete] = useState(null);
    const closeModalToDelete = useRef(null);
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(null)

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('poste', values.poste);
        formData.append('description', values.description);
        formData.append('description2', values.description2);
        formData.append('photo', values.photo);
        const responseProgramBroadcast = await teamRadioService.createTeamRadio(formData)
        try {
            if(responseProgramBroadcast.errorResponse) {
               toast.error(responseProgramBroadcast.message)
            } else {
                setIsChecked(false)
                listTeams(search, currentPage, numberPerPage)
                toast("La création de l'emission a été éffectué avec succès")
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation de l'émission")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    const listTeams = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page; 
        data.numberPerPage = numberPerPage;
        setLoading(true);
        try {
            const responseListTeams = await teamRadioService.listAllTeam(data)
            if (responseListTeams.errorResponse) {
                toast.error(responseListTeams.message)
            } else {
                setTeams(responseListTeams.results);
                setTotalNumberOfPage(responseListTeams.totalResult)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listTeams(search, currentPage, numberPerPage)
    }, [search, currentPage, numberPerPage]);

    const deleteATeam = async (id) => {
        try {
            setLoading(true)
            const response = await teamRadioService.deleteATeam(id)
            if (response?.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("La team a été Supprimé avec succès")
                listTeams(search, currentPage, numberPerPage);
                console.log("Suppression ensuite =>>")
                closeModalToDelete.current.click();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du post chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setLoading(false)
        }
    };

    const handleTeamsPerPageChange = (e) => {
        setCurrentPage(0);
        setNumberPerPage(Number(e.target.value));
        return ;
    }

    const handleSearchTeam = (event) => {
        const searchTeam = event.target.value;
        setSearch(searchTeam)
        setCurrentPage(0)
    }


    return (
        <>
            <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'une team radio</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                            <div className="accordion-stn">
                                <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-collapse notika-accrodion-cus">
                                        <div className="panel-heading" role="tab">
                                            <h4 className="panel-title">
                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                    Cliquez ici pour voir le formulaire
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                            <div className="panel-body">
                                                <Formik
                                                    initialValues={{ name: '', poste: '', description: '', description2: '', photo: null }}
                                                    validationSchema={Yup.object().shape({
                                                        name: Yup.string().required('Name is required'),
                                                        poste: Yup.string().required('Poste is required'),
                                                        description: Yup.string().required('Description is required'),
                                                        description2: Yup.string(), // Not required
                                                        photo: Yup.mixed().required('Photo is required')
                                                    })}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {({ setFieldValue }) => (
                                                        <Form className="form-example-wrap">
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Name</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            type="text"
                                                                            name="name"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter Name"
                                                                        />
                                                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Poste</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            type="text"
                                                                            name="poste"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter Poste"
                                                                        />
                                                                        <ErrorMessage name="poste" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Description</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter Description"
                                                                        />
                                                                        <ErrorMessage name="description" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Description 2</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description2"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter Description 2"
                                                                        />
                                                                        <ErrorMessage name="description2" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Photo</label>
                                                                    <div className="nk-int-st">
                                                                        <input
                                                                            type="file"
                                                                            name="photo"
                                                                            className="form-control input-sm"
                                                                            accept=".jpg, .jpeg, .png"
                                                                            onChange={(event) => setFieldValue('photo', event.currentTarget.files[0])}
                                                                        />
                                                                        <ErrorMessage name="photo" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int mg-t-15">
                                                                <button type="submit" className="btn btn-success notika-btn-success">
                                                                    Cliquez ici pour créer une team radio
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="accordion-area">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="accordion-wn-wp mg-t-30">
                                  <div className="accordion-hd">
                                      <h2>Filtre par la recherche et par le nombre d'élément</h2>
                                  </div>
                                  <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                          <div className="accordion-stn" >
                                                <div className="col-6">
                                                    <div class="dataTables_length" id="data-table-basic_length">
                                                        <label>
                                                          Sélectionner 
                                                            <select 
                                                                name="data-table-basic_length" 
                                                                aria-controls="data-table-basic" 
                                                                class="" 
                                                                value={totalNumberPerPage} 
                                                                onChange={handleTeamsPerPageChange}
                                                            >
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="100">100</option>
                                                            </select> 
                                                          posts par page
                                                        </label>
                                                    </div>
                                                </div>
                                                  
                                                <div className='col-6'>
                                                    <div id="data-table-basic_filter" class="dataTables_filter">
                                                        <label>Rechercher:
                                                            <input 
                                                                type="search" 
                                                                class="" 
                                                                placeholder="Titre, description" 
                                                                aria-controls="data-table-basic" 
                                                                onChange={handleSearchTeam}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                              
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                    <div className="container" style={{marginTop: "4px"}} >
                        <div class="row">
                            {teams.map((team, index) => (
                                <div key={index} class="col-md-4">
                                    <div class="panel panel-primary">
                                        <div class="panel-heading">
                                            <h3 class="panel-title">{team.name}</h3>
                                        </div>
                                        <div class="panel-body">
                                            <p>{team.poste}</p>
                                            <p><strong>Jours de diffusion :</strong> {team.description}</p>
                                        </div>
                                        <div class="panel-footer text-right">
                                            <a 
                                                role='button'
                                                style={{marginRight: "4px"}} 
                                                data-toggle="modal"
                                                data-target="#myModalOfATeam"
                                                class="btn btn-primary"
                                                onClick={() => setTeamUpdateOrDelete(team)}
                                            >
                                                En savoir plus
                                            </a>
                                            <button 
                                                class="btn btn-danger"
                                                data-toggle="modal"
                                                data-target="#myModalToDeleteATeam"
                                                onClick={() => setTeamUpdateOrDelete(team)}
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}    
                        </div>
                    </div>
                    {/* // <!-- Modal Structure --> */}
                    
                    <div class="modal fade" id="myModalOfATeam" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                {/* <!-- Modal Header --> */}
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 class="modal-title" id="myModalLabel">Informations de l'équipe</h4>
                                </div>
                                {/* <!-- Modal Body --> */}
                                <div class="modal-body">
                                    {/* <!-- Name Field --> */}
                                    <div class="form-group">
                                        <label for="name">Nom:</label>
                                        <p id="name">{teamUpdateOrDelete?.name}</p>
                                    </div>
                                    {/* <!-- Poste Field --> */}
                                    <div class="form-group">
                                        <label for="poste">Poste:</label>
                                        <p id="poste">{teamUpdateOrDelete?.poste}</p>
                                    </div>
                                    {/* <!-- Description Field --> */}
                                    <div class="form-group">
                                        <label for="description">Description:</label>
                                        <p id="description">{teamUpdateOrDelete?.description}</p>
                                    </div>
                                    {/* <!-- Description2 Field --> */}
                                    <div class="form-group">
                                        <label for="description2">Description Additionnelle:</label>
                                        <p id="description2">{teamUpdateOrDelete?.description2}</p>
                                    </div>
                                    {/* <!-- Photo Field --> */}
                                    <div class="form-group">
                                        <label for="photo">Photo:</label>
                                        <div id="photo">
                                            <img src={process.env.REACT_APP_SERVER+teamUpdateOrDelete?.photo} alt="Photo de Jean Dupont" class="img-responsive img-thumbnail"/>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Modal Footer --> */}
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Modal Structure for Deletion Confirmation --> */}
                    <div class="modal fade" id="myModalToDeleteATeam" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                {/* <!-- Modal Header --> */}
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 class="modal-title" id="deleteModalLabel">Confirmation de Suppression</h4>
                                </div>
                                {/* <!-- Modal Body --> */}
                                <div class="modal-body">
                                    <p>Êtes-vous sûr de vouloir supprimer cette équipe ? Cette action est irréversible.</p>
                                </div>
                                {/* <!-- Modal Footer --> */}
                                <div class="modal-footer">
                                    <button 
                                        type="button" 
                                        class="btn btn-default" 
                                        data-dismiss="modal"
                                        ref={closeModalToDelete}
                                    >
                                        Annuler
                                    </button>
                                    <button 
                                        type="button" 
                                        class="btn btn-danger" 
                                        onClick={() => deleteATeam(teamUpdateOrDelete.id)}
                                    >
                                        Confirmer la Suppression
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Teams;