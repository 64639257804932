import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import communityService from '../services/communityService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getIdAndtypeCommunity } from '../features/postInCommunity/postInCommunitySlice';
import clanService from '../services/clanService';
import { getNameOfAclan } from '../features/notableInClan/notableInAClanSlice';
import ReactPaginate from 'react-paginate';



function Clans(props) {
    const dispatch = useDispatch()
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [search, setSearch] = useState("");
    const [numberPerPage, setNumberPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [clans, setClans] = useState([]);
    const [totalNumberOfPage , setTotalNumberOfPage] = useState(null);
    const navigate = useNavigate();
    const [clanUpdateOrDelete, setClanUpdateOrDelete] = useState(null);
    const closeModalToDelete = useRef(null);
    const closeModalToUpdate = useRef(null);

    const validationSchema = Yup.object().shape({
        nom: Yup.string().required('Le nom est requis'),
        photo: Yup.mixed()
          .required('A file is required')
          .test(
            'fileType',
            'Only jpg, png, jpeg images are allowed',
            (value) => {
              if (value) {
                const fileType = value.type;
                return (
                  fileType === 'image/jpeg' ||
                  fileType === 'image/jpg' ||
                  fileType === 'image/png' 
                );
              }
              return false;
            }
          ),
        description: Yup.string().required('La description est requise'),
      });

      const validationUpdateClanSchema = Yup.object().shape({
        name: Yup.string().required('Le nom est requis'),
        // photo: Yup.mixed()
        //   .required('A file is required')
        //   .test(
        //     'fileType',
        //     'Only jpg, png, jpeg images are allowed',
        //     (value) => {
        //       if (value) {
        //         const fileType = value.type;
        //         return (
        //           fileType === 'image/jpeg' ||
        //           fileType === 'image/jpg' ||
        //           fileType === 'image/png' 
        //         );
        //       }
        //       return false;
        //     }
        //   ),
        description: Yup.string().required('La description est requise'),
        description2: Yup.string()
      });

    const handleClick = (nameClan) => {
        const withoutSpace = nameClan.name.replace(/\s+/g, '-')
        navigate(`/clans/${withoutSpace}/${nameClan.id}`);
    };

    

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    // Initial values
    const initialValues = {
        nom: '',
        photo: null, // Photo initialisée avec `null`
        description: '',
        description2: ''
    };

    const listAllClans = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page; 
        data.numberPerPage = numberPerPage;
        // data.type = "diaspora"
        setLoading(true);
        try {
            const responseClans = await clanService.listClans(data)
            if (responseClans.errorResponse) {
                toast.error(responseClans.message)
            } else {
                setClans(responseClans.results);
                setTotalNumberOfPage(responseClans.numberOfPages)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listAllClans(search, currentPage, numberPerPage)
    }, [search, currentPage, numberPerPage])

    // Handle form submission
    const handleSubmit = async (values) => {
        setLoading(true);
        // Créez une instance de FormData
        const formData = new FormData();
        formData.append('name', values.nom); 
        formData.append('photo', values.photo); 
        formData.append('description', values.description);
        formData.append('description2', values.description2);

        const responseCommunity = await clanService.createClan(formData)
        
        try {
            if (responseCommunity.errorResponse) {
                toast.error("Erreur lors de la création d'une communauté");
            } else {
                toast("Cette diapora a été crée avec succès");
                setIsChecked(false);
                listAllClans(search, currentPage, numberPerPage)
                // resetForm()
            }
            
        } catch (error) {
            
        }
    };

    const handleSearchDiaspora = (event) => {
        const keywords = event.target.value;
        setSearch(keywords);
        setCurrentPage(0);
    };

    const handleDeleteClan = async(id) => {
        try {
            setLoading(true)
            const response = await clanService.deleteAClan(id)
            if (response?.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("La team a été Supprimé avec succès")
                listAllClans(search, currentPage, numberPerPage);
                console.log("Suppression ensuite =>>")
                closeModalToDelete.current.click();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du post chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateSubmit = async (values) => {
        // alert(JSON.stringify(values, null, 4))
        // const formData = new FormData();
        // formData.append('name', values.name)
        // formData.append('photo', values.photo)
        // formData.append('description', values.description)
        // formData.append('description2', values.description2);
        const clanData = {
            name: values.name,
            description: values.description,
            description2: values.description2
        }

        const responseUpdateClan = await clanService.updateClan(clanUpdateOrDelete.id, clanData);
        try {
            if (responseUpdateClan?.errorResponse) {
                toast.error(responseUpdateClan.message)
            } else {
                listAllClans(search, currentPage, numberPerPage);
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            toast.error("Une erreur inattendue s'est produite pendant la modification de ce clan")
        } finally {
            setLoading(false);
        }
    };

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        setCurrentPage(currentPageu)
        return ;
    
    };
    return (
        <>
            <Header />  
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un clan</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            {isChecked ? 
                (
                    <div class="accordion-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="accordion-wn-wp">
                                        <div class="accordion-hd">
                                            <h2>Formulaire de création d'un clan</h2>
                                            {/* <p>Ici vous aurez la possibilité de créer une diapora en remplissant un formulaire. <code>Cliquer ici pour créer une diapora</code></p> */}
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12">
                                                <div class="accordion-stn">
                                                    <div class="panel-group" data-collapse-color="nk-green" id="accordionGreen" role="tablist" aria-multiselectable="true">
                                                        <div class="panel panel-collapse notika-accrodion-cus">
                                                            <div class="panel-heading" role="tab">
                                                                <h4 class="panel-title">
                                                                    <a data-toggle="collapse" data-parent="#accordionGreen" href="#accordionGreen-one" aria-expanded="false">
                                                                            Cliquez ici pour créer un clan
                                                                        </a>
                                                                </h4>
                                                            </div>
                                                            <div id="accordionGreen-one" class="collapse in" role="tabpanel">
                                                                <div class="panel-body">
                                                                    {/* <!-- Form Examples area start--> */}
                                                                        {/* <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
                                                                        <Formik
                                                                            initialValues={initialValues}
                                                                            validationSchema={validationSchema}
                                                                            onSubmit={handleSubmit}
                                                                        >
                                                                        {({ setFieldValue }) => (
                                                                            <Form>
                                                                            {/* Champ Nom */}
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                <label htmlFor="nom">Nom</label>
                                                                                <div className="nk-int-st">
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="nom"
                                                                                        id="nom"
                                                                                        className="form-control input-sm"
                                                                                        placeholder="Entrez le nom"
                                                                                    />
                                                                                    <ErrorMessage name="nom" component="div" style={{ color: 'red' }} />
                                                                                </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Champ Photo */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                <label htmlFor="photo">Photo</label>
                                                                                    <div className="nk-int-st">
                                                                                        <input
                                                                                            name="photo"
                                                                                            type="file"
                                                                                            accept=".jpg,.jpeg,.png" 
                                                                                            className="form-control input-sm"
                                                                                            onChange={(event) => {
                                                                                                if (event.currentTarget.files?.length > 0) {
                                                                                                    setFieldValue("photo", event.currentTarget.files[0]);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <ErrorMessage name="photo" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Champ Description */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description">Description</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Veuillez saisir la description"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="description"
                                                                                            component="div"
                                                                                            style={{ color: 'red' }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* Champ Description */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description2">Description</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description2"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Veuillez saisir la description2"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="description2"
                                                                                            component="div"
                                                                                            style={{ color: 'red' }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Bouton de soumission */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <button type="submit" className="btn" style={{ background: "rgb(34, 58, 102)", color: "white" }}>
                                                                                Créer un clan
                                                                                </button>
                                                                            </div>
                                                                            </Form>
                                                                        )}
                                                                        </Formik>
                                                                    </div>             
                                                                {/* <!-- Form Examples area End--> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <div className="inbox-area" style={{marginTop: "15px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="inbox-text-list sm-res-mg-t-30">
                                        <div className="form-group">
                                            <div className="nk-int-st search-input search-overt">
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Entrer le nom d'une communauté..."
                                                    onChange={handleSearchDiaspora} 
                                                />
                                                {/* <button className="btn search-ib">Search</button> */}
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-inbox">
                                                <tbody>
                                                    {clans.map((clan, index) => (
                                                        <tr key={index} className="active">
                                                            <td>
                                                                <span 
                                                                    role='button' 
                                                                    onClick={() => handleClick(clan)}
                                                                >
                                                                    {clan.id}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span 
                                                                    role='button' 
                                                                    onClick={() => handleClick(clan)}
                                                                >
                                                                    {clan.name}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span onClick={() => handleClick(clan)}>
                                                                    {clan.type}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <button 
                                                                    type="button" 
                                                                    className="btn"
                                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }} 
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalToSeeProfilOfAClan"
                                                                    onClick={() => {setClanUpdateOrDelete(clan)}}
                                                                >
                                                                    Profil
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <a 
                                                                    className="btn"
                                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalToUpdate"
                                                                    onClick={() => {setClanUpdateOrDelete(clan)}}
                                                                >
                                                                    Modifier
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a 
                                                                    className="btn btn-danger notika-btn-primary waves-effect"
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalToDeleteAClan"
                                                                    onClick={() => {setClanUpdateOrDelete(clan)}}
                                                                >
                                                                    Supprimer
                                                                </a>
                                                            </td>
                                                            
                                                        </tr>
                                                    ))} 
                                                </tbody>
                                            </table>    
                                            <ReactPaginate
                                                key={currentPage}
                                                initialPage={currentPage}
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                pageCount={totalNumberOfPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handleChangeCurrentPage}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeLinkClassName={"active"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* <!-- Modal de Profil --> */}
            <div class="modal fade" id="myModalToSeeProfilOfAClan" tabindex="-1" role="dialog" aria-labelledby="profilModalLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        {/* <!-- En-tête du Modal --> */}
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title" id="profilModalLabel">Profil du {clanUpdateOrDelete?.name}</h4>
                        </div>
                        {/* <!-- Corps du Modal --> */}
                        <div class="modal-body">
                            {/* <!-- Nom --> */}
                            <div class="form-group">
                                <label for="name">Nom:</label>
                                <p id="name">{clanUpdateOrDelete?.name}</p>
                            </div>
                            {/* <!-- Description --> */}
                            <div class="form-group">
                                <label for="description">Description:</label>
                                <p id="description">{clanUpdateOrDelete?.description}</p>
                            </div>
                            {/* <!-- Description2 --> */}
                            <div class="form-group">
                                <label for="description2">Description Additionnelle:</label>
                                <p id="description2">{clanUpdateOrDelete?.description2}</p>
                            </div>
                            {/* <!-- Photo --> */}
                            <div class="form-group">
                                <label for="photo">Photo:</label>
                                <div id="photo">
                                    <img 
                                        src={process.env.REACT_APP_SERVER+clanUpdateOrDelete?.photo}
                                        alt="Photo du Clan" 
                                        className="img-responsive img-thumbnail" 
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Pied de page du Modal --> */}
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal de Confirmation */}
            <div className="modal fade" id="myModalToDeleteAClan" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel">Confirmation de Suppression</h4>
                        </div>
                        <div className="modal-body">
                            Êtes-vous sûr de vouloir supprimer ce clan ? Cette action est irréversible.
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDelete}
                            >
                                Annuler
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-danger" 
                                onClick={() => handleDeleteClan(clanUpdateOrDelete.id)}
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to update a clan  myModalToUpdate*/}
            <div className="modal fade" id="myModalToUpdate" role="dialog">
                <div className="modal-dialog modal-large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2>Modifier une communauté</h2>
                            {/* Formik form */}
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    name: clanUpdateOrDelete?.name || '',
                                    description: clanUpdateOrDelete?.description || '',
                                    description2: clanUpdateOrDelete?.description2 || '',
                                }}
                                validationSchema={validationUpdateClanSchema}
                                onSubmit={handleUpdateSubmit}
                            >
                                {() => (
                                    <Form className="form-example-wrap">
                                        {/* Champ Name */}
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Nom</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        className="form-control input-sm"
                                                        placeholder="Entrez le nom"
                                                    />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Champ Description */}
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        as="textarea"
                                                        name="description"
                                                        className="form-control input-sm"
                                                        placeholder="Veuillez saisir la description"
                                                    />
                                                    <ErrorMessage
                                                        name="description"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Champ Description2 */}
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Description Additionnelle</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        as="textarea"
                                                        name="description2"
                                                        className="form-control input-sm"
                                                        placeholder="Entrez des informations supplémentaires (optionnel)"
                                                    />
                                                    <ErrorMessage
                                                        name="description2"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Bouton Soumettre */}
                                        <div className="form-example-int mg-t-15">
                                            <button
                                                type="submit"
                                                className="btn"
                                                style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                            >
                                                Soumettre
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button
                                ref={closeModalToUpdate}
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Clans;