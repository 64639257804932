import { sPOST, sDELETE, sPOST_FILE , formDataPOST_FETCH} from "../http/http";
import backendUrls from '../urls/url';

const programBroadcastService = {
    createAProgramBroadcast: async function (data) {
        const url = backendUrls.ProgramBroadcast.createProgramEmission
        console.log('url ===>> ', url)
        const result = await sPOST(url, data).then((r) =>
        r.json())
        return result
    },
    listProgramBroadcast: async function (data) {
        const url = backendUrls.ProgramBroadcast.listProgramEmission;
        console.log('url qui veut bloquer ===>> ', url)
        const result = await sPOST(url, data)
        .then((r) => r.json())
       return result
    },
    deleteAProgram: async function(id) {
        const url = backendUrls.ProgramBroadcast.deleteProgramBroadcast+id;
        const result = await sDELETE(url)
        return result
    },
    updateABroadcast: async function (id, data) {
        const url = backendUrls.ProgramBroadcast.updateProgramBroadcast+id;
        const result = await sPOST(url, data).then((r) => r.json())
        return result
    },
    

}

export default programBroadcastService;
