import { sPOST, sGET } from "../http/http";
import backendUrls from '../urls/url';


const culturalWealthService = {

    listCulturalWealths: async function (data) {
        const url = backendUrls.CulturalWealth.getCulturalwealths;
        const result = await sPOST(url, data)
        .then((r) => r.json())
       return result
    }
}

export default culturalWealthService;