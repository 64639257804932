import { sPOST, sDELETE, sPOST_FILE, sGET } from "../http/http";
import backendUrls from '../urls/url';

const TreasureHouseService = {
    listPostInRoyalService: async function(data) {
        const url = backendUrls.TreasureHouse.listPostsForMuseum;
        const result = await sPOST(url, data).then((r) => r.json())
        return result
    }, 
    deletePost: async function(id) {
        const url = backendUrls.TreasureHouse.deleteCulturalWealth+id
        const result = await sDELETE(url)
        console.log("url de suppression ===>> ", url)
        return result
    }, 
    getDetailOfAnObjectTreasureHouse: async function (id) {
        console.log("pour detail ==>> ", id)
        const url = backendUrls.TreasureHouse.getDetailOfATreasureHouse+id
        const result = await sGET(url)
        return result
    },
    updateDetailOfAnObjectTreasure: async function (id, data) {
        const url = backendUrls.TreasureHouse.updateOfATreasureHouse+id
        const result = await sPOST(url, data).then((r) => r.json())
        return result
    }
}

export default TreasureHouseService;