import { createSlice } from '@reduxjs/toolkit'

export const postInCommunitySlice = createSlice({
    name: 'postInCommunity',
    initialState: {
        communityId: null,
        type: null
    },
    reducers: {
        getIdAndtypeCommunity: (state, action) => {
            const { communityId, type } = action.payload; 
            state.communityId = communityId; 
            state.type = type; 
        },
        
    }
})
// Action creators are generated for each case reducer function
export const { getIdAndtypeCommunity } = postInCommunitySlice.actions
export default postInCommunitySlice.reducer