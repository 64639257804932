import { createSlice } from '@reduxjs/toolkit'

export const leaderSlice = createSlice({
    name: 'leader',
    initialState: {
        leader: null,
    },
    reducers: {
        getALeader: (state, action) => {
            const { leader } = action.payload; 
            state.leader = leader; 
        },
        
    }
})
// Action creators are generated for each case reducer function
export const { getALeader } = leaderSlice.actions
export default leaderSlice.reducer