import { sPOST, sDELETE, sPOST_FILE, formDataPOST_FETCH, sGET} from "../http/http";
import backendUrls from '../urls/url';

const postService = {
    createPost: async function (data) {
      const url = backendUrls.Post.createAPost
      console.log('url ===>> ', url)
      const result = await sPOST(url, data).then((r) =>
      r.json())
      return result
      //return { status: success, message: message }
    },
    listPosts: async function(data) {
      const url = backendUrls.Post.listPosts;
      const result = await sPOST(url, data).then((r) => r.json())
      return result
    }, 
    deletePost: async function(id) {
      const url = backendUrls.Post.deletePost+id
      const result = await sDELETE(url)
      console.log("url de suppression ===>> ", url)
      //return result
    }, 
    deleteAnImageOfAPost: async function(id) {
      const url = backendUrls.Post.deleteAnImageOfAPost+id
      const result = await sDELETE(url).then((r) => r.json());
      return result;
    },
    deleteAnVideoOfAPost: async function(id) {
      const url = backendUrls.Post.deleteAnVideoOfAPost+id;
      const result = await sDELETE(url).then((r) => r.json())
      return result;
    }, 
    addPhotoPost: async function(data) {
      const url = backendUrls.Photo.addPhoto;
      const result = await sPOST_FILE(url, data).then((r) => r.json())
      return result;
    },
    addVideoPost: async function(data) {
      const url = backendUrls.Video.addVideo;
      const result = await formDataPOST_FETCH(url, data).then((r) => r.json())
      return result;
    },
    getDetailOfAnObjectTreasureHouse: async function (id) {
      console.log("pour detail ==>> ", id)
      const url = backendUrls.TreasureHouse.getDetailOfATreasureHouse+id
      const result = await sGET(url)
      return result
    },
    updateDetailOfTraditionalDanse: async function (id, data) {
      const url = backendUrls.Post.upadtePost+id
      const result = await sPOST(url, data).then((r) => r.json())
      return result
    },
    showPost: async function (id) {
      const url = backendUrls.Post.showPost+id;
      const result = await sGET(url).then((r) => r.json());
      return result
    }

}

export default postService;