import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import programBroadcastService from '../services/programBroadcastService';
import { toast } from 'react-toastify';
import Footer from '../components/Footer';


function ProgramBroadcast(props) {
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const [programsBroadcast, setProgramsBroadcast] = useState([]);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null);
    const [programBroadcastdelete, setProgramBroadcastDelete] = useState(null);
    const [programBroadcastdetail, setProgramBroadcastDetail] = useState(null);
    const closeModalToDelete = useRef(null)
    const closeModalToUpdate = useRef(null);
    const [dayOfTheWeek,setDayOfTheWeek]= useState({});

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const programBroadcastData = {
            title: values.title,
            description: values.description,
            monday: values?.monday || "",
            tuesday: values?.tuesday || "", 
            wednesday: values?.wednesday || "", 
            thursday: values?.thursday || "", 
            friday: values?.friday || "", 
            saturday: values?.saturday || "", 
            sunday: values?.sunday || "", 
        };

        // Supprime directement les propriétés ayant des chaînes vides ou des valeurs null
        for (const key in programBroadcastData) {
            if (programBroadcastData[key] === "" || programBroadcastData[key] === null) {
            delete programBroadcastData[key];
            }
        }
        const responseProgramBroadcast = await programBroadcastService.createAProgramBroadcast(programBroadcastData)
        try {
            if(responseProgramBroadcast.errorResponse) {
               toast.error(responseProgramBroadcast.message)
            } else {
                setIsChecked(false)
                listProgramBroacast(search, currentPage, numberPerPage)
                toast("La création de l'emission a été éffectué avec succès")
    
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation de l'émission")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    const listProgramBroacast = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page+1; 
        data.numberPerPage = numberPerPage;
        for (const key in dayOfTheWeek) {
            if (dayOfTheWeek.hasOwnProperty(key)) {
                data[key] = dayOfTheWeek[key];
            }
        }
        setLoading(true);
        try {
            const responseProgramBroacast = await programBroadcastService.listProgramBroadcast(data)
            if (responseProgramBroacast.errorResponse) {
                toast.error(responseProgramBroacast.message)
            } else {
                setProgramsBroadcast(responseProgramBroacast.results);
                setTotalNumberOfPage(responseProgramBroacast.totalResult)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listProgramBroacast(search, currentPage, numberPerPage)
    }, [search, currentPage, numberPerPage, dayOfTheWeek]);

    const deleteProgram = async (id) => {
        // console.log("Suppression")
        try {
            setLoading(true)
            const response = await programBroadcastService.deleteAProgram(id)
            if (response?.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("Le post a été Supprimé avec succès")
                console.log("Suppression avant =>>")
                listProgramBroacast(search, currentPage, numberPerPage);
                // console.log("Ensuite fermer =>> ", closeModalToDelete.current)
                closeModalToDelete.current.click();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du post chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateSubmit = async (values) => {
        setLoading(true);
        const programBroadcastData = {
            title: values.title,
            description: values.description,
            monday: values?.monday || "",
            tuesday: values?.tuesday || "", 
            wednesday: values?.wednesday || "", 
            thursday: values?.thursday || "", 
            friday: values?.friday || "", 
            saturday: values?.saturday || "", 
            sunday: values?.sunday || "", 
        };

        // Supprime directement les propriétés ayant des chaînes vides ou des valeurs null
        for (const key in programBroadcastData) {
            if (programBroadcastData[key] === "" || programBroadcastData[key] === null) {
            delete programBroadcastData[key];
            }
        }
        const responseProgramBroadcast = await programBroadcastService.updateABroadcast(programBroadcastdelete.id, programBroadcastData)
        try {
            if(responseProgramBroadcast.errorResponse) {
               toast.error(responseProgramBroadcast.message)
            } else {
                listProgramBroacast(search, currentPage, numberPerPage);
                closeModalToUpdate.current.click()
                toast("La création de l'emission a été éffectué avec succès")
    
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation de l'émission")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    // console.log("program brodcast delete ===>>> ", programBroadcastdelete)

    return (
        <>
            <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un Programme</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                            <div className="accordion-stn">
                                <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-collapse notika-accrodion-cus">
                                        <div className="panel-heading" role="tab">
                                            <h4 className="panel-title">
                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                    Cliquez ici pour voir le formulaire
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                            <div className="panel-body">
                                                <Formik
                                                    initialValues={{
                                                        title: '',
                                                        description: '',
                                                        monday: '',
                                                        tuesday: '',
                                                        wednesday: '',
                                                        thursday: '',
                                                        friday: '',
                                                        saturday: '',
                                                        sunday: '',
                                                    }}
                                                    validationSchema={Yup.object({
                                                        title: Yup.string().required('Title is required'),
                                                        description: Yup.string().required('Description is required'),
                                                        monday: Yup.string(),
                                                        tuesday: Yup.string(),
                                                        wednesday: Yup.string(),
                                                        thursday: Yup.string(),
                                                        friday: Yup.string(),
                                                        saturday: Yup.string(),
                                                        sunday: Yup.string(),
                                                    })}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {({ setFieldValue, values }) => (
                                                        <Form className="form-example-wrap">
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Title</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            type="text"
                                                                            name="title"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter Title"
                                                                        />
                                                                        <ErrorMessage name="title" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Description</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter Description"
                                                                        />
                                                                        <ErrorMessage name="description" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                                                                <div className="form-example-int" key={day}>
                                                                    <div className="form-group">
                                                                        <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                                                        <div className="nk-int-st">
                                                                            <Field
                                                                                type="text" // Champ de texte pour l'heure
                                                                                name={day}
                                                                                className="form-control input-sm"
                                                                                placeholder={`Enter time for ${day.charAt(0).toUpperCase() + day.slice(1)}`}
                                                                            />
                                                                            <ErrorMessage name={day} component="div" className="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="form-example-int mg-t-15">
                                                                <button type="submit" className="btn btn-success notika-btn-success">
                                                                    Créer une émission radio
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div class="form-element-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-element-list">
                                        <div class="basic-tb-hd">
                                            <h2>Filtrer en cliquant sur les jours</h2>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="all" 
                                                    onClick={() => setDayOfTheWeek({})} 
                                                />
                                                <span class="checkmark"></span>
                                                Tous
                                            </label>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="monday" 
                                                    onClick={() => setDayOfTheWeek({'monday': 1})} 
                                                />
                                                <span class="checkmark"></span>
                                                Lundi
                                            </label>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="tuesday" 
                                                    onClick={() => setDayOfTheWeek({'tuesday': 1})} 
                                                />
                                                <span class="checkmark"></span>
                                                Mardi
                                            </label>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="wednesday" 
                                                    onClick={() => setDayOfTheWeek({'wednesday': 1})} 
                                                />
                                                <span class="checkmark"></span>
                                                Mercredi
                                            </label>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="thursday" 
                                                    onClick={() => setDayOfTheWeek({'thursday': 1})} 
                                                />
                                                <span class="checkmark"></span>
                                                Jeudi
                                            </label>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="friday" 
                                                    onClick={() => setDayOfTheWeek({'friday': 1})} 
                                                />
                                                <span class="checkmark"></span>
                                                Vendredi
                                            </label>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="saturday" 
                                                    onClick={() => setDayOfTheWeek({'saturday': 1})} 
                                                />
                                                <span class="checkmark"></span>
                                                Samedi
                                            </label>
                                            <label class="custom-radio">
                                                <input 
                                                    type="radio" 
                                                    name="dayWeek" 
                                                    id="sunday" 
                                                    onClick={() => setDayOfTheWeek({'sunday': 1})} 
                                                />
                                                <span class="checkmark"></span>
                                                Dimanche
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{marginTop: "4px"}} >
                        <div class="row">
                            {programsBroadcast.map((programBroadcast, index) => {
                                return (   
                                    <div key={index} class="col-md-6">
                                        <div class="custom-card">
                                            <div class="card-title">
                                                {programBroadcast.title}
                                            </div>
                                            <div class="day-time">
                                                <span>Lundi</span>
                                                <span>{programBroadcast.monday}</span>
                                            </div>
                                            <div class="day-time">
                                                <span>Mardi</span>
                                                <span>{programBroadcast.Tuesday}</span>
                                            </div>
                                            <div class="day-time">
                                                <span>Mercredi</span>
                                                <span>{programBroadcast.wednesday}</span>
                                            </div>
                                            <div class="day-time">
                                                <span>Jeudi</span>
                                                <span>{programBroadcast.thursday}</span>
                                            </div>
                                            <div class="day-time">
                                                <span>Vendredi</span>
                                                <span>{programBroadcast.friday}</span>
                                            </div>
                                            <div class="day-time">
                                                <span>Samedi</span>
                                                <span>{programBroadcast.saturday}</span>
                                            </div>
                                            <div class="day-time">
                                                <span>Dimanche</span>
                                                <span>{programBroadcast.sunday}</span>
                                            </div>
                                            <div className="button-container">
                                                <button 
                                                    className="btn"
                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                    data-toggle="modal" 
                                                    data-target="#detailsModal"
                                                    onClick={()=> setProgramBroadcastDetail(programBroadcast)}
                                                >
                                                    Voir les Détails
                                                </button>
                                                <button 
                                                    className="btn"
                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                    data-toggle="modal"
                                                    data-target="#updateModal"
                                                    onClick={()=> setProgramBroadcastDelete(programBroadcast)}
                                                >
                                                    Modifier
                                                </button>
                                                <button 
                                                    class="btn btn-danger"
                                                    data-toggle="modal"
                                                    data-target="#deleteModal"
                                                    onClick={() => setProgramBroadcastDelete(programBroadcast)}
                                                >Supprimer</button>
                                            </div>
                                        </div>
                                    </div>           
                                );
                            })} 
                        </div>
                    </div>
                </>
            )}

            {/* Modal pour voir la description */}
            <div id="detailsModal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* En-tête du modal */}
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title"></h4>
                        </div>
                        <div className="modal-body">
                            {/* <p><strong>Message : {traditionalDanseUpdateOrDelete?.message}</strong> </p> */}
                            <p><strong>Description : {programBroadcastdetail?.description}</strong> </p>
                        </div>
                        {/* Pied du modal */}
                        <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">
                            Fermer
                        </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="updateModal" class="modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title">Mettre à jour le programme</h4>
                        </div>
                        <div class="modal-body">
                            {/* <!-- Formik Form --> */}
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    title: programBroadcastdelete?.title || '',
                                    description: programBroadcastdelete?.description || '',
                                    monday: programBroadcastdelete?.monday || '',
                                    tuesday: programBroadcastdelete?.tuesday || '',
                                    wednesday: programBroadcastdelete?.wednesday || '',
                                    thursday: programBroadcastdelete?.thursday || '',
                                    friday: programBroadcastdelete?.friday || '',
                                    saturday: programBroadcastdelete?.saturday || '',
                                    sunday: programBroadcastdelete?.sunday || '',
                                }}
                                validationSchema={Yup.object({
                                    title: Yup.string().required('Le titre est obligatoire'),
                                    description: Yup.string().required('La description est obligatoire'),
                                    monday: Yup.string(),
                                    tuesday: Yup.string(),
                                    wednesday: Yup.string(),
                                    thursday: Yup.string(),
                                    friday: Yup.string(),
                                    saturday: Yup.string(),
                                    sunday: Yup.string(),
                                })}
                                onSubmit={handleUpdateSubmit}
                            >
                                {({ setFieldValue, values }) => (
                                    <Form className="form-example-wrap">
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Titre</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        type="text"
                                                        name="title"
                                                        className="form-control input-sm"
                                                        placeholder="Entrer le titre"
                                                    />
                                                    <ErrorMessage name="title" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        as="textarea"
                                                        name="description"
                                                        className="form-control input-sm"
                                                        placeholder="Entrer la description"
                                                    />
                                                    <ErrorMessage name="description" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                                            <div className="form-example-int" key={day}>
                                                <div className="form-group">
                                                    <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                                    <div className="nk-int-st">
                                                        <Field
                                                            type="text" // Champ de texte pour l'heure
                                                            name={day}
                                                            className="form-control input-sm"
                                                            placeholder={`Entrer l'heure pour ${day.charAt(0).toUpperCase() + day.slice(1)}`}
                                                        />
                                                        <ErrorMessage name={day} component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="form-example-int mg-t-15">
                                            <button 
                                                type="submit" 
                                                style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                className="btn"
                                            >
                                                Mettre à jour le programme
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToUpdate}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id={`deleteModal`} class="modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title">Confirmer la suppression de {programBroadcastdelete?.title}</h4>
                        </div>
                        <div class="modal-body">
                            <p>Êtes-vous sûr de vouloir supprimer ce programme ? Cette action est irréversible.</p>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDelete}
                            >
                                Annuler
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-danger" 
                                onClick={()=>deleteProgram(programBroadcastdelete.id)}
                            >
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ProgramBroadcast;