import { sPOST, sGET, sDELETE } from "../http/http";
import backendUrls from '../urls/url';

const communityService = {
  createCommunity: async function (data) {
    const url = backendUrls.Community.createCommunity;
    console.log('url ===>> ', url)
    const result = await sPOST(url, data).then((r) =>
    r.json())
    return result
  },
  listAllCommunity: async function (data) {
    const url = backendUrls.Community.getAllCommunities;
    const result = await sPOST(url, data)
    .then((r) => r.json())
    return result
  }, 
  deleteACommunity: async function(id) {
    const url = backendUrls.Community.deleteACommunity+id;
    const result = await sDELETE(url)
    return result
  }, 
  updateDetailOfACommunity: async function (id, data) {
    const url = backendUrls.Community.updateOfACommunity+id;
    const result = await sPOST(url, data).then((r) => r.json())
    return result
  }
}

export default communityService;