import { sPOST, sDELETE, sPOST_FILE , formDataPOST_FETCH} from "../http/http";
import backendUrls from '../urls/url';

const notableService = {
    createNotable: async function (data) {
      const url = backendUrls.Notable.createNotable;
      const result = await formDataPOST_FETCH(url, data).then((r) =>
      r.json())
      return result
      //return { status: success, message: message }
    },
    listNotable: async function (data) {
        const url = backendUrls.Notable.listNotables;
        const result = await sPOST(url, data)
        .then((r) => r.json())
       return result
    },
    updateNotable: async function (id, data) {
      const url = backendUrls.Notable.updateNotable+id;
      const result = await formDataPOST_FETCH(url, data).then((r) => r.json())
      return result
    },
    deleteANotable: async function(id) {
      const url = backendUrls.Notable.deleteNotable+id;
      const result = await sDELETE(url)
      return result
    },
}

export default notableService;