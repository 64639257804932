import { createSlice } from '@reduxjs/toolkit'

export const notableInClanSlice = createSlice({
    name: 'notableInClan',
    initialState: {
        name: null,
        id: null
    },
    reducers: {
        getNameOfAclan: (state, action) => {
            const { name, id } = action.payload; 
            state.name = name; 
            state.id = id; 
        },
        
    }
})
// Action creators are generated for each case reducer function
export const { getNameOfAclan } = notableInClanSlice.actions
export default notableInClanSlice.reducer