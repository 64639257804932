import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import postService from '../services/postService';
import { toast } from 'react-toastify';
import communityService from '../services/communityService';


function Post(props) {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [communities, setCommunities] = useState([]);
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(0);
    const [totalResult, setTotalResult] = useState(null);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(0)
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(8)

    useEffect(() => {
        listAllCommunities(search, currentPage, totalNumberPerPage)
        console.log("voi les resultats total ==>, ")
    }, [search, currentPage, totalNumberPerPage])

    const listAllCommunities = async (search, page, numberPerPage) => {
        const data = {}
        data.search = search;
        data.page = page;
        data.numberPerPage = numberPerPage;
        setLoading(true);
        try {
            const response = await communityService.listAllCommunity(data)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                setCommunities(response.results);
                setTotalNumberOfPage(response.totalNumberOfPage)
                setTotalResult(response.totalResult)
            }
        } catch (error) {
            console.log('Error fetching communities')
        } finally {
            setLoading(false)
        }
        
    }
    // Validation schema avec Yup
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Le titre est requis')
            .max(100, 'Le titre ne peut pas dépasser 100 caractères'),
        message: Yup.string()
            .required('Le message est requis'),
        type: Yup.string()
            .required('Veuillez sélectionner un type'),
        community: Yup.number(),
        etat: Yup.string()
            .required('Veuillez sélectionner un état'), 
        description: Yup.string()
            .required('La description est requise')
            .max(500, 'La description ne peut pas dépasser 500 caractères'),
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        const postData = {
            title: values.title,
            description: values.description,
            message: values.message,
            type: values.type,
            etat: values.etat,
            community_id: values.community
        }

        //alert(values, null, 4)
        const responsePost = await postService.createPost(postData)

        try {
            if(responsePost?.errorResponse) {
                toast.error(responsePost.message)
            } else {
                //mettre à jour le state
                // navigate
                toast("Votre post a été crée avec succès")
                navigate('/')
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du post")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    }
    
    return (
        <>
            <Header />
            <div className="form-example-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-example-wrap">
                                <div className="cmp-tb-hd">
                                    <h2>Formulaire pour création des article</h2>
                                    <p>Un post crée sera visible par tous les utilisateurs de la communauté Bamougoum</p>
                                </div>
                                <Formik
                                    initialValues={{
                                        title: '',
                                        message: '',
                                        type: '',
                                        description: '',
                                        community: '', 
                                        etat: '',
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ setFieldValue }) => (
                                        <Form>
                                            {/* Champ Titre */}
                                            <div className="form-example-int">
                                                <div className="form-group">
                                                    <label htmlFor="title">Titre</label>
                                                    <div className="nk-int-st">
                                                        <Field
                                                            type="text"
                                                            name="title"
                                                            id="title"
                                                            className="form-control input-sm"
                                                            placeholder="Enter le titre du post"
                                                        />
                                                        <ErrorMessage name="title" component="div" style={{ color: 'red' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Champ Message */}
                                            <div className="form-example-int">
                                                <div className="form-group">
                                                    <label htmlFor="message">Message</label>
                                                    <div className="nk-int-st">
                                                        <Field
                                                            type="text"
                                                            name="message"
                                                            id="message"
                                                            className="form-control input-sm"
                                                            placeholder="Enter le message"
                                                        />
                                                        <ErrorMessage name="message" component="div" style={{ color: 'red' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Champ Type */}
                                            <div className="form-example-int mg-t-15">
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <div className="nk-int-st">
                                                        <Field as="select" name="type" className="form-control input-sm">
                                                            <option value="">Veuillez sélectionner le type</option>
                                                            <option value="news">Nouveauté</option>
                                                            <option value="event">Evènement</option>
                                                            <option value="history">Histoire</option>
                                                            <option value="museum">Musé</option>
                                                            <option value="danse">Danse</option>
                                                            <option value="richess">Richesse</option>
                                                            <option value="king">Roi</option>
                                                        </Field>
                                                        <ErrorMessage name="type" component="div" style={{ color: 'red' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Champ Communauté */}
                                            <div className="form-example-int mg-t-15">
                                                <div className="form-group">
                                                    <label>Communauté</label>
                                                    <div className="nk-int-st">
                                                        <Field as="select" name="community" className="form-control input-sm">
                                                            <option value="">Veuillez sélectionner la communauté</option>
                                                            {communities.map((community) => (
                                                                <option key={community?.id} value={community?.id}>
                                                                    {community?.name}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {/* Champ etat */}
                                            <div className="form-example-int mg-t-15">
                                                <div className="form-group">
                                                    <label>État</label>
                                                    <div className="nk-int-st">
                                                        <Field as="select" name="etat" className="form-control input-sm">
                                                            <option value="">Veuillez sélectionner l'état</option>
                                                            <option value="0">Inactif</option>
                                                            <option value="1">Actif</option>
                                                        </Field>
                                                        <ErrorMessage name="etat" component="div" style={{ color: 'red' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Champ Description */}
                                            <div className="form-example-int mg-t-15">
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <div className="nk-int-st">
                                                        <Field
                                                            as="textarea"
                                                            name="description"
                                                            className="form-control input-sm"
                                                            placeholder="Veuillez saisir la description du post"
                                                        />
                                                        <ErrorMessage 
                                                            name="description" 
                                                            component="div" 
                                                            style={{ color: 'red' }} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Bouton de soumission */}
                                            <div className="form-example-int mg-t-15">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-success notika-btn-success"
                                                >
                                                    Créer un post
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div> 
            <Footer />
        </>
    );
}

export default Post;