import React from 'react';

function Footer(props) {
    return (
        <>
            {/* <!-- Inbox area End--> */}
            {/* <!-- Start Footer area--> */}
            <div class="footer-copyright-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="footer-copy-right">
                                <p>Copyright © 2024
                                    . All rights reserved. Template by ...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;