import UserService from "../services/userService";

export const TOKEN_LIFE_TIME = 86400000 * 1; // 1 day of life time
export const TOKEN_KEY = "token";
export const EXPIRE_AT_KEY = "expired_at";
export const ROLE_KEY = "role";
export const USER_KEY = "user";
export const LAST_URL = "LAST_URL";

export const ADMIN_SPACE_PATH="/users";
export const USER_SPACE_PATH="/users"; 

export const APP_ROLES = {
    ROLE_USER: "ROLE_USER",
    ROLE_ADMIN: "ROLE_ADMIN"
}
const APP_ROLES_HIERARCHY = {
    ROLE_ADMIN: [APP_ROLES.ROLE_USER, APP_ROLES.ROLE_MANAGER],
    ROLE_MANAGER: [APP_ROLES.ROLE_USER]
};
export const saveAuthToken = (accessToken, expiredAt) => {
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(EXPIRE_AT_KEY, expiredAt.toString());
};

export const removeAuthToken = () => {
    localStorage.removeItem(EXPIRE_AT_KEY);
    localStorage.removeItem(TOKEN_KEY);
};

export const getAuthToken = () => {
    const accessToken = localStorage.getItem(TOKEN_KEY) || null;
    const expiresAt = Number(localStorage.getItem(EXPIRE_AT_KEY)) || null;
    const role = localStorage.getItem(ROLE_KEY) || null;
    const userSt = localStorage.getItem(USER_KEY) || null;
    const user = JSON.parse(userSt);
    return {
        expiresAt,
        accessToken,
        role,
        user
    }
};

export const getBearerAccessToken = () => {
    if(!isAuthenticated()){
        window.location="/login";    
    }
    return getAuthToken().accessToken;
}

export const getRole = () => {
    return getAuthToken().role;
}

export const getUser = () => {
    return getAuthToken().user;
}

export const signIn = (data) => {
    let now = (new Date()).getTime();
    // let expiresAt = JSON.stringify(now + TOKEN_LIFE_TIME);
    let expiresAt = (new Date(data.jwtExpireAt) ).getTime();
    localStorage.setItem(TOKEN_KEY, data.jwt);
    localStorage.setItem(ROLE_KEY, data.user.role);
    localStorage.setItem(EXPIRE_AT_KEY, expiresAt);
    localStorage.setItem(USER_KEY, JSON.stringify(data.user));
    return data.user ;
};

export const logout = async () => {
    
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ROLE_KEY);
    localStorage.removeItem(EXPIRE_AT_KEY);
    localStorage.removeItem(USER_KEY);
};

export const isAuthenticated = () => {
    // Check whether the current time is past the 
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem(EXPIRE_AT_KEY));

    let now = new Date().getTime();
    //alert ("expireAt: "+expiresAt+" and now: "+now);
    if (expiresAt) {
        return now < expiresAt;
    }
    return false;
};

export const hasCredential = (role) => {
    if (!isAuthenticated()) return false;
    const currentRole = getRole();
    return hasRole({ role: currentRole }, role);
}

export const hasRole = (user, role) => {
    const currentRole = user.role;
    if (currentRole !== null || currentRole !== undefined) {
        if (role == currentRole) {
            return true;
        }
        if (APP_ROLES_HIERARCHY[currentRole]) {
            let subRoles = APP_ROLES_HIERARCHY[currentRole];
            console.log(subRoles);
            for (let i = 0; i < subRoles.length; i++) {
                console.log(subRoles[i]);
                console.log(role);
                if (subRoles[i] == role) {
                    return true;
                }
            }
        }
    }
    return false;
}


export const refreshUser = async () => {
    if (!isAuthenticated()) return false;
    const user = await UserService.refreshUser();
    if (user) {
        localStorage.removeItem(USER_KEY);
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        return true;

    } else {
        return false;
    }

}

export const removeLastUrl = (url) => {
    let last = getLastUrl();
    if (last != null) {
        localStorage.removeItem(LAST_URL);
    }
}
export const getLastUrl = () => {
    const url = localStorage.getItem(LAST_URL);
    return url;
}


export const setLastUrl = (url) => {
    localStorage.setItem(LAST_URL, url);
}

export const redirectionUrl = (user) => {
    if (user.role == APP_ROLES.ROLE_USER){
        return USER_SPACE_PATH;
    }
    if (user.role == APP_ROLES.ROLE_ADMIN){
        return ADMIN_SPACE_PATH;
    }
    return ;
}

export const getRedirectionUrl = (user)=>{
    var last_url = getLastUrl()
    if (last_url){
        return last_url
    }
    return redirectionUrl(user)
}
