import React, { useState, useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { toast } from 'react-toastify';
import TreasureHouseService from '../services/treasureHouse';

function Notable(props) {
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [treasuresHouse, setTreasuresHouse] = useState([]);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(6);
    const closeModalToDelete = useRef(null);
    const [postUpdateOrDelete, setPostUpdateOrDelete] = useState(null)

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const listTreasuresHouse = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page; 
        data.numberPerPage = numberPerPage;
        data.type = "museum"
        setLoading(true);
        try {
            const responseDiaporaCommunity = await TreasureHouseService.listPostInRoyalService(data)
            if (responseDiaporaCommunity.errorResponse) {
                toast.error(responseDiaporaCommunity.message)
            } else {
                setTreasuresHouse(responseDiaporaCommunity.results);
                setTotalNumberOfPage(responseDiaporaCommunity.totalResult)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listTreasuresHouse(search, currentPage, numberPerPage)
    }, [search, currentPage, numberPerPage]);

    const deletePost = async (id) => {
        console.log('Voir id de la communauté ==> ', id)
        try {
            setLoading(true)
            const response = await TreasureHouseService.deletePost(id)
            if (response?.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("Le post a été Supprimé avec succès")
                console.log("Suppression avant =>>")
                listTreasuresHouse(search, currentPage, numberPerPage);
                console.log("Suppression ensuite =>>")
                closeModalToDelete.current.click();
                console.log("Suppression après =>>")
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du post chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setLoading(false)
        }
    };

    const handleSearchAnObject = (event) => {
        const searchAnObject = event.target.value;
        setSearch(searchAnObject)
        setCurrentPage(0)
    }
    return (
        <>
            <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un Post</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <div className="accordion-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="accordion-wn-wp mg-t-30">
                                    <div className="accordion-hd">
                                        <h2>Création d'un Post</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                            <div className="accordion-stn">
                                                <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                                    <div className="panel panel-collapse notika-accrodion-cus">
                                                        <div className="panel-heading" role="tab">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                                    Cliquez ici pour voir le formulaire
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                                            <div className="panel-body">
                                                                <div className="form-example-wrap">
                                                                    <div className="form-example-int">
                                                                        <div className="form-group">
                                                                            <label>Nom</label>
                                                                            <div className="nk-int-st">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="form-control input-sm" 
                                                                                    placeholder="Entrer le nom, description" 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-example-int">
                                                                        <div className="form-group">
                                                                            <label>Description</label>
                                                                            <div className="nk-int-st">
                                                                                <textarea 
                                                                                    type="text" 
                                                                                    className="form-control input-sm" 
                                                                                    placeholder="Veuillez saisir la description" 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-example-int">
                                                                        <div className="form-group">
                                                                            <label>Sélectionner une image</label>
                                                                            <div className="nk-int-st">
                                                                                <input 
                                                                                    type="file" 
                                                                                    className="form-control input-sm" 
                                                                                    placeholder="Enter Email" 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="form-example-int mg-t-15">
                                                                        <button className="btn btn-success notika-btn-success">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="accordion-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="accordion-wn-wp mg-t-30">
                                        <div className="accordion-hd">
                                            <h2>Filtre par la recherche et par le nombre d'élément</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                                <div className="accordion-stn" >
                                                    <div className="col-6">
                                                        <div class="dataTables_length" id="data-table-basic_length">
                                                            <label>
                                                                Show 
                                                                <select name="data-table-basic_length" aria-controls="data-table-basic" class="">
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select> 
                                                                entries
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div id="data-table-basic_filter" class="dataTables_filter">
                                                            <label>Search:
                                                                <input 
                                                                    type="search" 
                                                                    class="" 
                                                                    placeholder="" 
                                                                    aria-controls="data-table-basic"
                                                                    onChange={handleSearchAnObject}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inbox-area" style={{marginTop: "15px"}}>       
                        <div class="container">    
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">  
                                    <div className="container">
                                        <div style={{display: "flex", gap: "5px", flexWrap: "wrap"}}>
                                        {treasuresHouse.map((treasureHouse, index) => (
                                            <div key={index} >
                                                <div className="thumbnail" style={{ width: "100%" }}>
                                                    <div
                                                        className="image-container"
                                                        style={{
                                                        width: "100%",
                                                        height: "300px", // Taille fixe pour l'image de fond
                                                        backgroundImage: `url(${process.env.REACT_APP_SERVER}${treasureHouse?.photos[0]?.path})`,
                                                        backgroundSize: "cover", // Couvre tout le div
                                                        backgroundPosition: "center", // Centre l'image
                                                        backgroundRepeat: "no-repeat", // Pas de répétition de l'image
                                                        overflow: "hidden",
                                                        }}
                                                    ></div>
                                                    <div className="caption">
                                                        <h3>{treasureHouse.title}</h3>
                                                        {/* <p>{culturalWealth.description}</p> */}
                                                        <p>
                                                        <button
                                                            className="btn notika-btn-success"
                                                            role="button"
                                                            data-toggle="modal"
                                                            data-target="#myModaldescription"
                                                            onClick={() => setPostUpdateOrDelete(treasureHouse)}
                                                        >
                                                            Description
                                                        </button>
                                                        <button
                                                            className="btn notika-btn-amber"
                                                            style={{ marginLeft: "5px" }}
                                                            data-toggle="modal"
                                                            data-target="#myModaltwo"
                                                        >
                                                            Modifier
                                                        </button>
                                                        <button
                                                            className="btn btn-danger pull-right"
                                                            role="button"
                                                            data-toggle="modal"
                                                            data-target="#myModalToDeleteTreasureHouse"
                                                            onClick={() => setPostUpdateOrDelete(treasureHouse)}
                                                        >
                                                            Supprimer
                                                        </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div class="modal fade" id="myModaltwo" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="myModalthree" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal pour supprimer */}
            <div class="modal animated rubberBand" id="myModalToDeleteTreasureHouse" role="dialog">
                    <div class="modal-dialog modals-default">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                                <h2>Suppression d'un post</h2>
                                <p>Voulez-vous vraiment supprimer ce Post ?</p>
                            </div>
                            <div class="modal-footer">
                                <button 
                                    type="button" 
                                    class="btn btn-default" 
                                    data-dismiss="modal" 
                                    onClick={() => deletePost(postUpdateOrDelete.id)}
                                >
                                    Supprimer
                                </button>
                                <button 
                                    type="button" 
                                    class="btn btn-default" 
                                    data-dismiss="modal"
                                    ref={closeModalToDelete}
                                >
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
            <Footer />
        </>
    );
}

export default Notable;