import { sPOST, sGET, sDELETE, formDataPOST_FETCH } from "../http/http";
import backendUrls from '../urls/url';

const suggestionService = {
  
  getSuggestions: async function (data) {
    const url = backendUrls.Suggestions.listSuggestions;
    const result = await sPOST(url, data)
    .then((r) => r.json())
    return result
  },
  deleteSuggestion: async function(id) {
    const url = backendUrls.Suggestions.deleteSuggestion+id
    const result = await sDELETE(url)
    return result
  }, 
  
}

export default suggestionService;