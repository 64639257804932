import { sPOST, sGET, sDELETE, formDataPOST_FETCH } from "../http/http";
import backendUrls from '../urls/url';

const kingService = {
  createAMemberOfADynasty: async function (data) {
    const url = backendUrls.Dynasty.CreateAMemberOfTheDynasty;
    const result = await formDataPOST_FETCH(url, data).then((r) =>
    r.json())
    return result
  },
  getphotosKing: async function (data) {
    const url = backendUrls.King.getKing;
    const result = await sGET(url)
    .then((r) => r.json())
    return result
  },
  
}

export default kingService;