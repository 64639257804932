import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import postInCommunityReducer from '../features/postInCommunity/postInCommunitySlice';
import getNameOfAclanReducer  from '../features/notableInClan/notableInAClanSlice';
import getLeaderReducer  from '../features/leader/leaderSlice';

// Configuration de persist
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['postInCommunity', 'getNameOfAclan'], 
};

const rootReducer = combineReducers({
    postCommunity: postInCommunityReducer,
    getNameOfAclan: getNameOfAclanReducer,
    getLeader: getLeaderReducer

});

// Création du reducer persistant
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
            },
        }
    ),
});

// Création de l'instance de persistance
export const persistor = persistStore(store);

export default store;