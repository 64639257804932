import React, { useRef, useState , useEffect} from 'react';

import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UserService from '../services/userService';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { getRole } from '../auth/auth';
import communityService from '../services/communityService';


function Users(props) {
    const [loading, setLoading] = useState(false);
    const [communities, setCommunities] = useState([])
    const closeModalCreateUser = useRef(null);
    const [users, setUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState("");
    const [totalResult, setTotalResult] = useState(null)
    const [numberPerPage, setNumberPerPage] = useState(30);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null)
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(null);
    const [userToUpdate, setUserToUpdate] = useState(null);
    const closeModalToDelete = useRef(null)
    const closeModalToUpdate = useRef(null);
    const closeModalToUpdatePassword = useRef(null);

    const validationSchema = Yup.object({
        lastName: Yup.string().required('Nom est requis'),
        firstName: Yup.string().required('Prenom est requis'),
        ville: Yup.string().required('Ville est requis'),
        email: Yup.string().email('Email invalide').required('Email est requis'),
        login: Yup.string().required("Nom d'utilisateur est requis"),
        password: Yup.string()
          .required('Mot de passe est requis')
          .min(6, 'Le mot de passe doit contenir au moins 6 caractères'),
        role: Yup.string().required('Rôle est requis'),
        description: Yup.string(),
        phoneNumber: Yup.string()
          .required('Téléphoner est requis')
          .matches(/^[0-9]{9}$/, 'Le numéro de téléphoneNumber doit contenir 10 chiffres'),
        gender: Yup.string().required('Genre est requis'),
        community_id: Yup.number(),
    });

    const listAllUsers = async (search, currentPage, numberPerPage) => {
        const data = {}
        data.search = search;
        data.page = currentPage+1;
        data.numberPerPage = numberPerPage;

        try {
            const response = await UserService.getAllUsers(data)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                setUsers(response.results);
                setTotalNumberOfPage(response.totalNumberOfPage)
                setTotalResult(response.totalResult);
            }    
        } catch (error) {
            
        }
    }

    const handleSubmitToCreateUser = async (values) => {
        try {
            setLoading(true)
            const response = await UserService.createUser(values)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast("Le Utilisateur a été Crée avec succès")
                listAllUsers(search, currentPage, totalNumberPerPage);
                //resetForm()
                //closeModalCreateUser.current.click()
            }
        } catch (error) {
            //console.log("Erreur lors de la creation : ",error);
            toast.error("Une érreur inattendue s'est produite lors de la creation du clien")
        } finally {
            setLoading(false)
        }
        
    };

    const handleDeleteUser = async (id) => {
        try {
            setLoading(true)
            const response = await UserService.deleteAnUser(id)
            if (response?.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("L'utilisateur a été Supprimé avec succès")
                listAllUsers(search, currentPage, numberPerPage);
                // console.log("Suppression ensuite =>>")
                closeModalToDelete.current.click();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du post chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setLoading(false)
        }
    };

    const handleChangePassword = async (values) => {
        const passwordOfAnUserToUpdate = {
            newPassword: values.newPassword
        };

        const responsePasswordUserToUpdate = await UserService.updateUserInformations(userToUpdate.id, passwordOfAnUserToUpdate);
        try {
            if(responsePasswordUserToUpdate.errorResponse) {
                toast.error(responsePasswordUserToUpdate.message)
            } else {
                listAllUsers(search, currentPage, numberPerPage)
                toast("La Modification du password a été éffectuée ")
                closeModalToUpdatePassword.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la modification d'un utilisateur")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateUser = async (values) => {
        const userToUpdateData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            login: values.login,
            ville: values.ville,
            phoneNumber: values.phoneNumber,
        };

        const responseUserToUpdate = await UserService.updateUserInformations(userToUpdate.id, userToUpdateData)
        try {
            if(responseUserToUpdate.errorResponse) {
                toast.error(responseUserToUpdate.message)
            } else {
                listAllUsers(search, currentPage, numberPerPage)
                toast("La création de type danse traditionnelle a été éffectué avec succès")
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la modification d'un utilisateur")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        listAllUsers(search, currentPage, numberPerPage);
    }, [search, currentPage, numberPerPage])

    const handleSearchAnObject = (event) => {
        const searchAnObject = event.target.value;
        setSearch(searchAnObject)
        setCurrentPage(0)
    };

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        console.log('mon current ==>>> ', currentPageu)
        setCurrentPage(currentPageu)
        return ;
    };

    const listCommunities= async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page+1; 
        data.numberPerPage = numberPerPage;
        setLoading(true);
        try {
            const responseListCommunities = await communityService.listAllCommunity(data)
            if (responseListCommunities.errorResponse) {
                toast.error(responseListCommunities.message)
            } else {
                setCommunities(responseListCommunities.results);
                setTotalNumberOfPage(responseListCommunities .numberOfPages)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listCommunities(search, currentPage, numberPerPage)
    }, [])
    return (
        <>
            <Header />
	        {/* <!-- Breadcomb area End--> */}
            {/* <!-- Inbox area Start--> */}
            {/* <!-- Accordion area start--> */}
            <div className="accordion-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="accordion-wn-wp mg-t-30">
                                <div className="accordion-hd">
                                    <h2>Création d'un utilisateur</h2>
                                    {/* <p>Remplir le formulaire ci-dessous</p> */}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                        <div className="accordion-stn">
                                            <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                                <div className="panel panel-collapse notika-accrodion-cus">
                                                    <div className="panel-heading" role="tab">
                                                        <h4 className="panel-title">
                                                            <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                                Cliquez ici pour voir le formulaire
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                                        <div className="panel-body">
                                                            <div className="form-example-wrap">
                                                                <Formik
                                                                    initialValues={{
                                                                        lastName: '',
                                                                        firstName: '',
                                                                        ville: '',
                                                                        email: '',
                                                                        login: '',
                                                                        password: '',
                                                                        role: '',
                                                                        description: '',
                                                                        phoneNumber: '',
                                                                        gender: '',
                                                                        community_id: null
                                                                      }}
                                                                    
                                                                    validationSchema={validationSchema}
                                                                    onSubmit={(values) => {
                                                                        handleSubmitToCreateUser(values);    // Appelle la fonction de soumission de Formik
                                                                    }}        
                                                                    >
                                                                    {({ handleReset, handleSubmit }) => (
                                                                        <Form  onSubmit={handleSubmit} onReset={handleReset}>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                <label>Nom</label>
                                                                                <div className="nk-int-st">
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="lastName"
                                                                                        className="form-control input-sm"
                                                                                        placeholder="Entrez votre nom"
                                                                                    />
                                                                                    <ErrorMessage name="lastName" component="div" className="error-message" />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                <label>Prénom</label>
                                                                                <div className="nk-int-st">
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="firstName"
                                                                                        className="form-control input-sm"
                                                                                        placeholder="Entrez votre prélastName"
                                                                                    />
                                                                                    <ErrorMessage name="firstName" component="div" className="error-message" />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label>Ville</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="ville"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez votre ville"
                                                                                        />
                                                                                        <ErrorMessage 
                                                                                            name="ville" 
                                                                                            component="div" 
                                                                                            className="error-message" 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label>Email</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="email"
                                                                                            name="email"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez votre email"
                                                                                        />
                                                                                        <ErrorMessage 
                                                                                            name="email" 
                                                                                            component="div" 
                                                                                            className="error-message" 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                <label>Login</label>
                                                                                <div className="nk-int-st">
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="login"
                                                                                        className="form-control input-sm"
                                                                                        placeholder="Entrez votre lastName d'utilisateur"
                                                                                    />
                                                                                    <ErrorMessage name="login" component="div" className="error-message" />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label>Password</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="password"
                                                                                            name="password"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Mot de passe"
                                                                                        />
                                                                                        <ErrorMessage name="password" component="div" className="error-message" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label>Rôle</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field as="select" name="role" className="form-control input-sm">
                                                                                        <option value="">Sélectionnez un rôle</option>
                                                                                        <option value="ROLE_ADMIN">Admin</option>
                                                                                        <option value="ROLE_USER">Utilisateur</option>
                                                                                        <option value="ROLE_MANAGER">Manager</option>
                                                                                        </Field>
                                                                                        <ErrorMessage name="role" component="div" className="error-message" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                <label>Description</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez une description"
                                                                                        />
                                                                                        <ErrorMessage 
                                                                                            name="description" 
                                                                                            component="div" 
                                                                                            className="error-message" 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label>Téléphone</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="phoneNumber"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez votre numéro de téléphoneNumber"
                                                                                        />
                                                                                        <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label>Genre</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field as="select" name="gender" className="form-control input-sm">
                                                                                        <option value="">Sélectionnez un genre</option>
                                                                                        <option value="male">Homme</option>
                                                                                        <option value="female">Femme</option>
                                                                                        </Field>
                                                                                        <ErrorMessage 
                                                                                            name="gender" 
                                                                                            component="div" 
                                                                                            className="error-message" 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label>Communauté</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field as="select" name="community_id" className="form-control input-sm">
                                                                                            <option value="">Sélectionnez une communauté</option>
                                                                                            {communities.map((community) => (
                                                                                                <option key={community.id} value={community.id}>
                                                                                                    {community.name} 
                                                                                                </option>
                                                                                            ))}
                                                                                        </Field>
                                                                                        <ErrorMessage 
                                                                                            name="gender" 
                                                                                            component="div" 
                                                                                            className="error-message" 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button 
                                                                                type="submit" 
                                                                                className="btn btn-primary mg-t-15"
                                                                            >
                                                                                Soumettre
                                                                            </button>
                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inbox-area" style={{marginTop: "15px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="inbox-text-list sm-res-mg-t-30">
                                <div className="form-group">
                                    <div className="nk-int-st search-input search-overt">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            onChange={handleSearchAnObject}
                                            placeholder="Recherchez par le nom ou prénom..." />
                                    </div>
                                </div>
                                {/*  */}
                                <div className="table-responsive">
                                    <table className="table table-hover table-inbox">
                                        <tbody>
                                            {users.map((user, index) => (
                                                <tr key={index} className="unread">
                                                    <td><span>{user.firstName}</span></td>
                                                    <td><span>{user.lastName}</span></td>
                                                    <td><span>{user.gender === "male" ? "Homme" : "Femme"}</span></td>
                                                    <td 
                                                        className="text-right mail-date"
                                                    >
                                                        {user.role === "ROLE_ADMIN" && "ADMIN"}
                                                    </td>
                                                    <td>
                                                        <button 
                                                            type="button" 
                                                            className="btn"
                                                            style={{ background: "rgb(34, 58, 102)", color: "white" }} 
                                                            data-toggle="modal" 
                                                            data-target="#myModalToProfil"
                                                            onClick={() => setUserToUpdate(user)}
                                                        >
                                                            Profil
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn"
                                                            style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                            data-toggle="modal" 
                                                            data-target="#myModalToUpdateAnUser"
                                                            onClick={() => setUserToUpdate(user)}
                                                        >
                                                            Modifier
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn"
                                                            style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                            data-toggle="modal"
                                                            data-target="#myModalToUpdateAnPassword"
                                                            onClick={() => setUserToUpdate(user)}
                                                        >
                                                            Modifier Password
                                                        </a>
                                                    </td>
                                                    {getRole() === "ROLE_ADMIN" && (
                                                        <td>
                                                            <a 
                                                                className="btn btn-danger"
                                                                data-toggle="modal" 
                                                                data-target="#myModalToDeleteAnUser"
                                                                onClick={() => setUserToUpdate(user)}
                                                            >
                                                                Supprimer
                                                            </a>
                                                        </td>
                                                    )} 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <ReactPaginate
                                        key={currentPage}
                                        initialPage={currentPage}
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        pageCount={totalNumberOfPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handleChangeCurrentPage}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeLinkClassName={"active"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Inbox area End--> */}
            {/* <!-- Start Footer area--> */}
            <Footer />
            <div className="modal animated bounce" id="myModalfour" role="dialog">
                <div className="modal-dialog modals-default">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to see profil */}
            <div id="myModalToProfil" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    {/* <!-- Contenu du modal --> */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Profil de l'Utilisateur</h4>
                        </div>
                        <div className="modal-body">
                            {/* <!-- Informations à afficher --> */}
                            <p><strong>Nom :</strong>{userToUpdate?.lastName}</p>
                            <p><strong>Prénom :</strong>{userToUpdate?.firstName}</p>
                            <p><strong>Adresse e-mail :</strong>{userToUpdate?.email}</p>
                            <p><strong>Téléphone :</strong>{userToUpdate?.phoneNumber}</p>
                            <p><strong>Description :</strong>{userToUpdate?.description}</p>
                            <p><strong>Ville :</strong>{userToUpdate?.ville}</p>
                            <p><strong>Sexe :</strong>{userToUpdate?.gender}</p>
                            <p><strong>Email :</strong>{userToUpdate?.email}</p>
                            <p><strong>Role :</strong>{userToUpdate?.role === "ROLE_ADMIN" ? "ADMINISTRATEUR" : "ROLE_MANAGER" ? "MANAGER" : "UTILISATEUR"}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to delete an user */}
            <div id="myModalToDeleteAnUser" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    {/* <!-- Contenu du modal --> */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Confirmer la Suppression</h4>
                        </div>
                        <div className="modal-body">
                            <p>Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action est irréversible.</p>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDelete}
                            >
                                Annuler
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-danger"
                                onClick={() => handleDeleteUser(userToUpdate.id)}  
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to update an user */}
            <div id="myModalToUpdateAnUser" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    {/* Contenu du modal */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Modifier l'Utilisateur</h4>
                        </div>
                        <div className="modal-body">
                            {/* Formulaire avec Formik */}
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    firstName: userToUpdate?.firstName || '',
                                    lastName: userToUpdate?.lastName || '',
                                    email: userToUpdate?.email || '',
                                    login: userToUpdate?.login || '',
                                    ville: userToUpdate?.ville || '',
                                    phoneNumber: userToUpdate?.phoneNumber || '',
                                    community_id: userToUpdate?.community_id || ''
                                }}
                                validationSchema={Yup.object().shape({
                                    firstName: Yup.string().required('Le prénom est requis'),
                                    lastName: Yup.string().required('Le nom est requis'),
                                    email: Yup.string().email('Email invalide').required('L\'email est requis'),
                                    login: Yup.string().required('Le login est requis'),
                                    ville: Yup.string().required('La ville est requise'),
                                    phoneNumber: Yup.string().required('Le numéro de téléphone est requis'),
                                    community_id: Yup.number()
                                })}
                                onSubmit={handleUpdateUser} // Fonction pour gérer la soumission
                            >
                                {() => (
                                    <Form className="form-example-wrap">
                                        <div className="form-group">
                                            <label>Prénom</label>
                                            <Field
                                                type="text"
                                                name="firstName"
                                                className="form-control input-sm"
                                                placeholder="Entrez le prénom"
                                            />
                                            <ErrorMessage name="firstName" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <Field
                                                type="text"
                                                name="lastName"
                                                className="form-control input-sm"
                                                placeholder="Entrez le nom"
                                            />
                                            <ErrorMessage name="lastName" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                className="form-control input-sm"
                                                placeholder="Entrez l'email"
                                            />
                                            <ErrorMessage name="email" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label>Login</label>
                                            <Field
                                                type="text"
                                                name="login"
                                                className="form-control input-sm"
                                                placeholder="Entrez le login"
                                            />
                                            <ErrorMessage name="login" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label>Ville</label>
                                            <Field
                                                type="text"
                                                name="ville"
                                                className="form-control input-sm"
                                                placeholder="Entrez la ville"
                                            />
                                            <ErrorMessage name="ville" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label>Numéro de Téléphone</label>
                                            <Field
                                                type="text"
                                                name="phoneNumber"
                                                className="form-control input-sm"
                                                placeholder="Entrez le numéro de téléphone"
                                            />
                                            <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label>Communauté</label>
                                            <Field as="select" name="community_id" className="form-control input-sm">
                                                {communities.map((community) => (
                                                    <option key={community.id} value={community.id}>
                                                        {community.name} 
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage 
                                                name="gender" 
                                                component="div" 
                                                className="error-message" 
                                            />
                                       
                                        </div>                                       
                                        <div className="form-group text-right">
                                            <button type="submit" className="btn" style={{ background: "rgb(34, 58, 102)", color: "white" }}>
                                                Mettre à jour
                                            </button>
                                            <button 
                                                type="button" 
                                                className="btn btn-default" 
                                                data-dismiss="modal" 
                                                style={{ marginLeft: '10px' }}
                                                ref={closeModalToUpdate}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to change password of an user */}
            <div id="myModalToUpdateAnPassword" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    {/* Contenu du modal */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Modifier le mot de passe</h4>
                        </div>
                        <div className="modal-body">
                            {/* Formulaire avec Formik */}
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    newPassword: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    newPassword: Yup.string().required('Le mot de passe est requis'),
                                })}
                                onSubmit={handleChangePassword} // Fonction pour gérer la soumission
                            >
                                {() => (
                                    <Form className="form-example-wrap">
                                        <div className="form-group">
                                            <label>Nouveau mot de passe</label>
                                            <Field
                                                type="text"
                                                name="newPassword"
                                                className="form-control input-sm"
                                                placeholder="Entrez le nouveau mot de passe"
                                            />
                                            <ErrorMessage name="newPassword" component="div" className="text-danger" />
                                        </div>                                      
                                        <div className="form-group text-right">
                                            <button type="submit" className="btn" style={{ background: "rgb(34, 58, 102)", color: "white" }}>
                                                Modifier mot de passe
                                            </button>
                                            <button 
                                                type="button" 
                                                className="btn btn-default" 
                                                data-dismiss="modal" 
                                                style={{ marginLeft: '10px' }}
                                                ref={closeModalToUpdatePassword}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Users;