import { sPOST, sGET, sDELETE, formDataPOST_FETCH } from "../http/http";
import backendUrls from '../urls/url';

const leaderService = {
  createALeader: async function (data) {
    const url = backendUrls.Leader.createLeader;
    const result = await sPOST(url, data).then((r) =>
    r.json())
    return result
  },
  getLeaders: async function (data) {
    const url = backendUrls.Leader.listAllLeader;
    const result = await sPOST(url, data)
    .then((r) => r.json())
    return result
  },
  getALeader: async function (id) {
    const url = backendUrls.Leader.getALeader+id;
    const result = await sGET(url)
    .then((r) => r.json())
    return result
  },
  updateLeader: async function (id, data) {
    const url = backendUrls.Leader.updateLeader+id;
    const result = await sPOST(url, data).then((r) => r.json())
    return result
  }
  
}

export default leaderService;