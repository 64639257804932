import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UserService from '../services/userService';
import { toast } from 'react-toastify';
import { getRedirectionUrl, signIn } from '../auth/auth';
import { useNavigate } from 'react-router-dom';
import logo from '../images/images/ndop2.jpeg'
import { Circles } from 'react-loader-spinner'

function Login(props) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    // Schéma de validation Yup
    const validationSchema = Yup.object().shape({
        login: Yup.string().required('L’email est requis'),
        password: Yup.string().min(3, 'Le mot de passe doit contenir au moins 6 caractères').required('Mot de passe requis')
    });

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const response = await UserService.login(values)
      console.log("Voir response ==> ", response)
      if (response.errorResponse) {
        //setFieldError(response.message)
        toast.error(response.message)
      } 
      if (!response.errorResponse) {
        let user=signIn(response)
        window.location.href=getRedirectionUrl(user)  

      }
    } catch (error) {
        console.log(error);
        
      console.log("Veuillez démarrer le l'autre serveur")
    } finally {
        setLoading(false)
    }
   
  }

    return (
        <>
            <div 
                // style={{
                //     backgroundImage: `url(${logo})`, // Utilise l'importation pour le style
                //     height: '100vh',
                //     backgroundSize: 'auto', // Utilise auto pour conserver la taille originale de l'image
                //     backgroundPosition: 'center',
                //     backgroundRepeat: 'repeat' // Ce
                // }}
            >
                <div className="div-center">
                    {loading && (
                        <div style={{
                            position: 'absolute', // Position absolue pour centrer l'élément
                            top: '50%', // Positionne à 50% de la hauteur du conteneur
                            left: '50%', // Positionne à 50% de la largeur du conteneur
                            transform: 'translate(-50%, -50%)' // Ajuste pour centrer précisément
                        }}>
                            <Circles /> {/* Ton composant Circles ici */}
                        </div>
                    )}
                    <div className="content">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <img src='/images/images/logo.PNG' />
                        <h3>Se Connecter au formulaire</h3>
                        <hr />
                        <Formik
                            initialValues={{
                                login: '',
                                password: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ handleReset }) => (
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="login">Nom d'utilisateur</label>
                                    <Field
                                        type="text"
                                        name="login"
                                        id="login"
                                        className="form-control"
                                        placeholder="Entrer votre Nom d'utilisateur"
                                        autoComplete="username"
                                    />
                                    <ErrorMessage name="login" component="div" className="error-message" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Mot de passe</label>
                                    <Field
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="form-control"
                                        placeholder="Password"
                                        autoComplete="current-password"
                                    />
                                    <ErrorMessage 
                                        name="password" 
                                        component="div" 
                                        className="error-message" 
                                    />
                                </div>
                                <button type="submit">Se Connecter</button>
                                <hr />
                            </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;