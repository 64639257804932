import {POST, sGET,  sPOST, sPUT,  sDELETE, sPATCH} from '../http/http';
import backendUrls from '../urls/url';

const UserService = {
  login: async function (data) {
    const url = backendUrls.Authentication.login;
    console.log('url ===>> ', url)
    const result = await POST(url, data).then((r) =>
    r.json())
    console.log('my login ==> ',result)
    return result
    //return { status: success, message: message }
  },
  createUser: async function (data) {
    const url = backendUrls.USER.createAnUser;
    console.log('url =>> ', url)
    const result = await sPOST(url, data)
    .then(r => r.json());
    return result;
  }, 

  createAccount: async function (data) {
    const url = backendUrls.USER.createUserWithRole;
    console.log('url =>> ', url)
    const result = await sPOST(url, data).then((r) =>
    r.json());
    return result
  },
  userInformationsDetail: async function(id) {
    const url = backendUrls.USER.userInformationsDetail+id;
    console.log(`url detail du user ===>> ${url}`)
    const result = await sGET(url).then((r) => 
    r.json())
    return result;
  },
  updateUserInformations: async function (id, data) {
    const url = backendUrls.USER.updateAnUser+id;
    console.log("Voir données du user ==> ", data)
    const result = await sPOST(url, data).then((r) => 
    r.json())
    return result

  }, 
  updatePassword: async function (data) {
    const url = backendUrls.USER.updatePassword;
    const result = await sPOST(url, data)
    .then((r) => 
      r.json()
    );
    return result;
  },

  getAllUsers: async function (data) {
    const url = backendUrls.USER.getAllUsers;
    const result = await sPOST(url, data).then((r) =>
    r.json());
    return result;
  },
  deleteAnUser: async function(id) {
    const url = backendUrls.USER.deleteAnUser+id;
    const result = await sDELETE(url)
    return result
  },
};
export default UserService;