import Actuality from "../pages/Actuality";
import Clans from "../pages/Clans";
import Community from "../pages/Community";
import CommunityPosts from "../pages/CommunityPosts";
import CulturalWealth from "../pages/CulturalWealth";
import Diaspora from "../pages/Diaspora";
import GenesisKingdom from "../pages/GenesisKingdom";
import HandingPostInACommunity from "../pages/HandingPostInACommunity";
import HistoricalEvents from "../pages/HistoricalEvents";
import Login from "../pages/Login";
import Notable from "../pages/Notable";
import NotableInClan from "../pages/NotableInClan";
import Podcasts from "../pages/Podcasts";
//import Login from "../pages/Login";
import Post from "../pages/Post";
import Posts from "../pages/Posts";
import ProgramBroadcast from "../pages/ProgramBroadcast";
import Teams from "../pages/Teams";
import TraditionalDanse from "../pages/TraditionalDanse";
import TreasureHouse from "../pages/TreasureHouse";
//import Register from "../pages/Register";
import Users from "../pages/Users";
import DiasporaPosts from "../pages/DiasporaPosts";
import TheKing from "../pages/TheKing";
import ContentPostOfKing from "../pages/ContentPostOfKing";
import Leaders from "../pages/Leaders";
import Events from "../pages/Events";
import Dynasty from "../pages/Dynasty";
import NguembaLanguage from "../pages/NguembaLanguage";
import ContentPostOfLeader from "../pages/ContentPostOfLeader";
import PostContentLeader from "../pages/PostContentLeader";
import Suggestions from "../pages/Suggestions";
import CommunityPostsWhichNotNews from "../pages/CommunityPostWhichNotNews";
import CommunityToPostNotNews from "../pages/CommunityToPostNotNews";
import DiasporaToPostsNotNews from "../pages/DiasporaToPostsNotNews";
import DiasporaPostsWhichNotNews from "../pages/DiasporaPostWhichNotNews";
import RoyalPlaceNotOnlyNews from "../pages/RoyalPlaceNotOnlyNews";
import CurrentEvents from "../pages/CurrentEvents";


export const spaces = [
    {
        name: "public",
        secured: false,
        routes: [
            {
                path: '/login',
                component: Login,
                exact: true
            }
            
        ]
    }
    ,
    {
        name: "userspace",
        secured: true,
        role : "ROLE_USER",
        routes: [
            {
             
                path: '/',
                component: Events,
                exact: true
            },
            {
                
                path: '/users',
                component: Users,
                exact: true
            },
            {
                
                path: '/leaders',
                component: Leaders,
                exact: true
            },
            {
                
                path: '/leader/post/:id',
                component: PostContentLeader,
                exact: true
            },
            {
                
                path: '/leader/:name/:id',
                component: ContentPostOfLeader,
                exact: true
            },
            {
                
                path: '/king/:id',
                component: ContentPostOfKing,
                exact: true
            },
            {
                
                path: '/danse/:id',
                component: ContentPostOfKing,
                exact: true
            },
            {
                path: '/posts-in-diaspora/:nameCommunity/:id',
                component: DiasporaPosts, // Remplacez par votre composant pour afficher les posts
                exact: true
            },
            {
                path: '/posts-in-diaspora-not-only-news',
                component: DiasporaPostsWhichNotNews,
                exact: true
            },
            {
                path: '/diaspora/:id',
                component: ContentPostOfKing, // Remplacez par votre composant pour afficher les posts
                exact: true,
            },
            {
                
                path: '/posts-in-community/:name/:id',
                component: CommunityPosts,
                exact: true
            },
            {
                
                path: '/posts-in-community-which-not-new/:name/:id',
                component: CommunityPostsWhichNotNews,
                exact: true
            },
            {
                path: 'posts-in-diaspora-not-only-news/:name/:id',
                component: DiasporaPostsWhichNotNews,
                exact: true
            },
            {
                
                path: '/community/:id',
                component: ContentPostOfKing,
                exact: true
            },
            {
                
                path: '/post',
                component: Post,
                exact: true
            },
            {
                
                path: '/the-king',
                component: TheKing,
                exact: true
            },
            {
                path: '/notables',
                component: Notable,
                exact: true
            },
            {
                path: '/traditional-danse',
                component: TraditionalDanse,
                exact: true
            },
            {
                path: '/community',
                component: Community,
                exact: true
            },
            {
                path: '/community-to-posts-not-news',
                component: CommunityToPostNotNews,
                exact: true
            },
            {
                path: '/genesis-kingdom',
                component: GenesisKingdom,
                exact: true
                
            },
            {
                path: '/post-genesis/:id',
                component: ContentPostOfKing,
                exact: true
            },
            {
                path: '/clans',
                component: Clans,
                exact: true
            },
            {
                path: '/clans/:nameClans/:id',
                component: NotableInClan,
                exact: true
            },
            {
                
                path: '/teams',
                component: Teams,
                exact: true
               
            },
            {
                
                path: '/nguemba-language',
                component: NguembaLanguage,
                exact: true
            },
            {
                
                path: '/post-nguemba-language/:id',
                component: ContentPostOfKing,
                exact: true
            },
            {
                
                path: '/post-in-cummunity',
                component: HandingPostInACommunity,
                exact: true
            },
            {
                
                path: '/actility-royal-place',
                component: Actuality,
                exact: true
            },
            {
                path: 'royal-place-with-posts-not-only-news',
                component: RoyalPlaceNotOnlyNews,
                exact: true
            },
            {
                
                path: '/cultural-wealth',
                component: CulturalWealth,
                exact: true
            },
            {
             
                path: '/diaspora',
                component: Diaspora,
                exact: true,   
            },
            {
                path: '/diaspora-to-all-posts',
                component: DiasporaToPostsNotNews,
                exact: true
            },
            {
             
                path: '/programs-broadcast',
                component: ProgramBroadcast,
                exact: true     
            },
            {
             
                path: '/museum',
                component: TreasureHouse,
                exact: true     
            },
            {
             
                path: '/dynasty',
                component: Dynasty,
                exact: true     
            },
            {
             
                path: '/events',
                component: Events,
                exact: true     
            },
            {
             
                path: '/event/:id',
                component: ContentPostOfKing,
                exact: true     
            },
            {
             
                path: '/podcasts',
                component: Podcasts,
                exact: true     
            },
            
            {
                
                path: '/historical-events',
                component: HistoricalEvents,
                exact: true
            },
            {
                
                path: '/teams-radio',
                component: Teams,
                exact: true
            },
            {
                path: '/suggestions',
                component: Suggestions,
                exact: true
            },
            {
                path: '/current-events',
                component: CurrentEvents,
                exact: true
            }
        ]
    },
    {
        name: "admin",
        secured: true,
        role : "ROLE_ADMIN",
        routes: [
            
        ]
    }     
]

export const flattenSpace = (spaces) =>{
    let flatten = [];
    spaces.forEach(space => {
    const {name,secured,role,routes} = space;
    routes.forEach(route=>{
        let element={}
        element.namespace=name;
        element.role=role;
        element.secured=secured;
        element.path=route.path;
        element.exact=route.exact;
        element.component=route.component;
        flatten.push(element);
    })
    
   });
   return flatten;
}

export const allRoutes = flattenSpace(spaces);